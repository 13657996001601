import { gql } from "@apollo/client";

const CREATE_CAMPAIGN = gql`
  mutation brandCreateCampaign(
    $name: String!
    $description: String!
    $campaignType: Int!
    $media: Int!
    $ageGroup: [String!]!
    $malePercentage: Int
    $femalePercentage: Int
    $gallery: [String!]!
    $userId: ID!
    $categoryId: ID!
    $followerPreference: [String!]!
    $contract: String
    $stories: Boolean
    $reels: Boolean
    $videos: Boolean
    $pictures: Boolean
    $requiresUsageRights: Boolean
    $usageRightsPeriod: String
    $cost: Int
    $commissionModel: Boolean
    $costType: String
    $startDate: ISO8601Date
    $endDate: ISO8601Date

  ) {
    brandCreateCampaign(
      name: $name
      description: $description
      campaignType: $campaignType
      media: $media
      ageGroup: $ageGroup
      malePercentage: $malePercentage
      femalePercentage: $femalePercentage
      gallery: $gallery
      userId: $userId
      categoryId: $categoryId
      followerPreference: $followerPreference
      contract: $contract
      stories: $stories
      reels: $reels
      videos: $videos
      pictures: $pictures
      requiresUsageRights: $requiresUsageRights
      usageRightsPeriod: $usageRightsPeriod
      cost: $cost
      costType: $costType
      startDate: $startDate
      endDate: $endDate
      commissionModel: $commissionModel

    )
  }
`;

export default CREATE_CAMPAIGN;
