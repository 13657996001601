// currencies.js
const currencies = [
  { label: 'USD - US Dollar', value: 'USD', symbol: '$' },
  { label: 'EUR - Euro', value: 'EUR', symbol: '€' },
  { label: 'GBP - British Pound', value: 'GBP', symbol: '£' },
  { label: 'JPY - Japanese Yen', value: 'JPY', symbol: '¥' },
  { label: 'AUD - Australian Dollar', value: 'AUD', symbol: 'A$' },
  { label: 'CAD - Canadian Dollar', value: 'CAD', symbol: 'C$' },
  { label: 'CHF - Swiss Franc', value: 'CHF', symbol: 'CHF' },
  { label: 'CNY - Chinese Yuan', value: 'CNY', symbol: '¥' },
  { label: 'SEK - Swedish Krona', value: 'SEK', symbol: 'kr' },
  { label: 'NZD - New Zealand Dollar', value: 'NZD', symbol: 'NZ$' },
  { label: 'AED - United Arab Emirates Dirham', value: 'AED', symbol: 'د.إ' },
  { label: 'AFN - Afghan Afghani', value: 'AFN', symbol: '؋' },
  { label: 'ALL - Albanian Lek', value: 'ALL', symbol: 'L' },
  { label: 'AMD - Armenian Dram', value: 'AMD', symbol: '֏' },
  { label: 'ANG - Netherlands Antillean Guilder', value: 'ANG', symbol: 'ƒ' },
  { label: 'AOA - Angolan Kwanza', value: 'AOA', symbol: 'Kz' },
  { label: 'ARS - Argentine Peso', value: 'ARS', symbol: '$' },
  { label: 'AWG - Aruban Florin', value: 'AWG', symbol: 'ƒ' },
  { label: 'AZN - Azerbaijani Manat', value: 'AZN', symbol: '₼' },
  { label: 'BAM - Bosnia-Herzegovina Convertible Mark', value: 'BAM', symbol: 'KM' },
  { label: 'BBD - Barbadian Dollar', value: 'BBD', symbol: 'Bds$' },
  { label: 'BDT - Bangladeshi Taka', value: 'BDT', symbol: '৳' },
  { label: 'BGN - Bulgarian Lev', value: 'BGN', symbol: 'лв' },
  { label: 'BHD - Bahraini Dinar', value: 'BHD', symbol: '.د.ب' },
  { label: 'BIF - Burundian Franc', value: 'BIF', symbol: 'FBu' },
  { label: 'BMD - Bermudian Dollar', value: 'BMD', symbol: 'BD$' },
  { label: 'BND - Brunei Dollar', value: 'BND', symbol: 'B$' },
  { label: 'BOB - Bolivian Boliviano', value: 'BOB', symbol: 'Bs.' },
  { label: 'BRL - Brazilian Real', value: 'BRL', symbol: 'R$' },
  { label: 'BSD - Bahamian Dollar', value: 'BSD', symbol: 'B$' },
  { label: 'BTN - Bhutanese Ngultrum', value: 'BTN', symbol: 'Nu.' },
  { label: 'BWP - Botswana Pula', value: 'BWP', symbol: 'P' },
  { label: 'BYN - Belarusian Ruble', value: 'BYN', symbol: 'Br' },
  { label: 'BZD - Belize Dollar', value: 'BZD', symbol: 'BZ$' },
  { label: 'CDF - Congolese Franc', value: 'CDF', symbol: 'FC' },
  { label: 'CLP - Chilean Peso', value: 'CLP', symbol: '$' },
  { label: 'COP - Colombian Peso', value: 'COP', symbol: '$' },
  { label: 'CRC - Costa Rican Colón', value: 'CRC', symbol: '₡' },
  { label: 'CUP - Cuban Peso', value: 'CUP', symbol: '$' },
  { label: 'CZK - Czech Koruna', value: 'CZK', symbol: 'Kč' },
  { label: 'DJF - Djiboutian Franc', value: 'DJF', symbol: 'Fdj' },
  { label: 'DKK - Danish Krone', value: 'DKK', symbol: 'kr' },
  { label: 'DOP - Dominican Peso', value: 'DOP', symbol: 'RD$' },
  { label: 'DZD - Algerian Dinar', value: 'DZD', symbol: 'دج' },
  { label: 'EGP - Egyptian Pound', value: 'EGP', symbol: '£' },
  { label: 'ERN - Eritrean Nakfa', value: 'ERN', symbol: 'Nkf' },
  { label: 'ETB - Ethiopian Birr', value: 'ETB', symbol: 'Br' },
  { label: 'FJD - Fijian Dollar', value: 'FJD', symbol: 'FJ$' },
  { label: 'FKP - Falkland Islands Pound', value: 'FKP', symbol: '£' },
  { label: 'FOK - Faroese Króna', value: 'FOK', symbol: 'kr' },
  { label: 'GEL - Georgian Lari', value: 'GEL', symbol: '₾' },
  { label: 'GGP - Guernsey Pound', value: 'GGP', symbol: '£' },
  { label: 'GHS - Ghanaian Cedi', value: 'GHS', symbol: 'GH₵' },
  { label: 'GIP - Gibraltar Pound', value: 'GIP', symbol: '£' },
  { label: 'GMD - Gambian Dalasi', value: 'GMD', symbol: 'D' },
  { label: 'GNF - Guinean Franc', value: 'GNF', symbol: 'FG' },
  { label: 'GTQ - Guatemalan Quetzal', value: 'GTQ', symbol: 'Q' },
  { label: 'GYD - Guyanese Dollar', value: 'GYD', symbol: 'G$' },
  { label: 'HKD - Hong Kong Dollar', value: 'HKD', symbol: 'HK$' },
  { label: 'HNL - Honduran Lempira', value: 'HNL', symbol: 'L' },
  { label: 'HRK - Croatian Kuna', value: 'HRK', symbol: 'kn' },
  { label: 'HTG - Haitian Gourde', value: 'HTG', symbol: 'G' },
  { label: 'HUF - Hungarian Forint', value: 'HUF', symbol: 'Ft' },
  { label: 'IDR - Indonesian Rupiah', value: 'IDR', symbol: 'Rp' },
  { label: 'ILS - Israeli New Shekel', value: 'ILS', symbol: '₪' },
  { label: 'IMP - Isle of Man Pound', value: 'IMP', symbol: '£' },
  { label: 'INR - Indian Rupee', value: 'INR', symbol: '₹' },
  { label: 'IQD - Iraqi Dinar', value: 'IQD', symbol: 'ع.د' },
  { label: 'IRR - Iranian Rial', value: 'IRR', symbol: '﷼' },
  { label: 'ISK - Icelandic Króna', value: 'ISK', symbol: 'kr' },
  { label: 'JEP - Jersey Pound', value: 'JEP', symbol: '£' },
  { label: 'JMD - Jamaican Dollar', value: 'JMD', symbol: 'J$' },
  { label: 'JOD - Jordanian Dinar', value: 'JOD', symbol: 'JD' },
  { label: 'KES - Kenyan Shilling', value: 'KES', symbol: 'KSh' },
  { label: 'KGS - Kyrgyzstani Som', value: 'KGS', symbol: 'лв' },
  { label: 'KHR - Cambodian Riel', value: 'KHR', symbol: '៛' },
  { label: 'KID - Kiribati Dollar', value: 'KID', symbol: '$' },
  { label: 'KMF - Comorian Franc', value: 'KMF', symbol: 'CF' },
  { label: 'KRW - South Korean Won', value: 'KRW', symbol: '₩' },
  { label: 'KWD - Kuwaiti Dinar', value: 'KWD', symbol: 'د.ك' },
  { label: 'KYD - Cayman Islands Dollar', value: 'KYD', symbol: '$' },
  { label: 'KZT - Kazakhstani Tenge', value: 'KZT', symbol: '₸' },
  { label: 'LAK - Lao Kip', value: 'LAK', symbol: '₭' },
  { label: 'LBP - Lebanese Pound', value: 'LBP', symbol: 'ل.ل' },
  { label: 'LKR - Sri Lankan Rupee', value: 'LKR', symbol: 'Rs' },
  { label: 'LRD - Liberian Dollar', value: 'LRD', symbol: 'L$' },
  { label: 'LSL - Lesotho Loti', value: 'LSL', symbol: 'L' },
  { label: 'LYD - Libyan Dinar', value: 'LYD', symbol: 'ل.د' },
  { label: 'MAD - Moroccan Dirham', value: 'MAD', symbol: 'د.م.' },
  { label: 'MDL - Moldovan Leu', value: 'MDL', symbol: 'L' },
  { label: 'MGA - Malagasy Ariary', value: 'MGA', symbol: 'Ar' },
  { label: 'MKD - Macedonian Denar', value: 'MKD', symbol: 'ден' },
  { label: 'MMK - Myanmar Kyat', value: 'MMK', symbol: 'K' },
  { label: 'MNT - Mongolian Tögrög', value: 'MNT', symbol: '₮' },
  { label: 'MOP - Macanese Pataca', value: 'MOP', symbol: 'MOP$' },
  { label: 'MRU - Mauritanian Ouguiya', value: 'MRU', symbol: 'UM' },
  { label: 'MUR - Mauritian Rupee', value: 'MUR', symbol: '₨' },
  { label: 'MVR - Maldivian Rufiyaa', value: 'MVR', symbol: 'Rf' },
  { label: 'MWK - Malawian Kwacha', value: 'MWK', symbol: 'MK' },
  { label: 'MXN - Mexican Peso', value: 'MXN', symbol: '$' },
  { label: 'MYR - Malaysian Ringgit', value: 'MYR', symbol: 'RM' },
  { label: 'MZN - Mozambican Metical', value: 'MZN', symbol: 'MT' },
  { label: 'NAD - Namibian Dollar', value: 'NAD', symbol: 'N$' },
  { label: 'NGN - Nigerian Naira', value: 'NGN', symbol: '₦' },
  { label: 'NIO - Nicaraguan Córdoba', value: 'NIO', symbol: 'C$' },
  { label: 'NOK - Norwegian Krone', value: 'NOK', symbol: 'kr' },
  { label: 'NPR - Nepalese Rupee', value: 'NPR', symbol: '₨' },
  { label: 'OMR - Omani Rial', value: 'OMR', symbol: 'ر.ع.' },
  { label: 'PAB - Panamanian Balboa', value: 'PAB', symbol: 'B/.' },
  { label: 'PEN - Peruvian Sol', value: 'PEN', symbol: 'S/.' },
  { label: 'PGK - Papua New Guinean Kina', value: 'PGK', symbol: 'K' },
  { label: 'PHP - Philippine Peso', value: 'PHP', symbol: '₱' },
  { label: 'PKR - Pakistani Rupee', value: 'PKR', symbol: '₨' },
  { label: 'PLN - Polish Złoty', value: 'PLN', symbol: 'zł' },
  { label: 'PYG - Paraguayan Guaraní', value: 'PYG', symbol: '₲' },
  { label: 'QAR - Qatari Riyal', value: 'QAR', symbol: 'ر.ق' },
  { label: 'RON - Romanian Leu', value: 'RON', symbol: 'lei' },
  { label: 'RSD - Serbian Dinar', value: 'RSD', symbol: 'дин.' },
  { label: 'RUB - Russian Ruble', value: 'RUB', symbol: '₽' },
  { label: 'RWF - Rwandan Franc', value: 'RWF', symbol: 'FRw' },
  { label: 'SAR - Saudi Riyal', value: 'SAR', symbol: '﷼' },
  { label: 'SBD - Solomon Islands Dollar', value: 'SBD', symbol: 'SI$' },
  { label: 'SCR - Seychellois Rupee', value: 'SCR', symbol: '₨' },
  { label: 'SDG - Sudanese Pound', value: 'SDG', symbol: 'ج.س.' },
  { label: 'SGD - Singapore Dollar', value: 'SGD', symbol: 'S$' },
  { label: 'SHP - Saint Helena Pound', value: 'SHP', symbol: '£' },
  { label: 'SLL - Sierra Leonean Leone', value: 'SLL', symbol: 'Le' },
  { label: 'SOS - Somali Shilling', value: 'SOS', symbol: 'Sh' },
  { label: 'SRD - Surinamese Dollar', value: 'SRD', symbol: '$' },
  { label: 'SSP - South Sudanese Pound', value: 'SSP', symbol: '£' },
  { label: 'STN - São Tomé and Príncipe Dobra', value: 'STN', symbol: 'Db' },
  { label: 'SYP - Syrian Pound', value: 'SYP', symbol: '£' },
  { label: 'SZL - Eswatini Lilangeni', value: 'SZL', symbol: 'L' },
  { label: 'THB - Thai Baht', value: 'THB', symbol: '฿' },
  { label: 'TJS - Tajikistani Somoni', value: 'TJS', symbol: 'ЅМ' },
  { label: 'TMT - Turkmenistani Manat', value: 'TMT', symbol: 'm' },
  { label: 'TND - Tunisian Dinar', value: 'TND', symbol: 'د.ت' },
  { label: 'TOP - Tongan Paʻanga', value: 'TOP', symbol: 'T$' },
  { label: 'TRY - Turkish Lira', value: 'TRY', symbol: '₺' },
  { label: 'TTD - Trinidad and Tobago Dollar', value: 'TTD', symbol: 'TT$' },
  { label: 'TVD - Tuvaluan Dollar', value: 'TVD', symbol: '$' },
  { label: 'TWD - New Taiwan Dollar', value: 'TWD', symbol: 'NT$' },
  { label: 'TZS - Tanzanian Shilling', value: 'TZS', symbol: 'Sh' },
  { label: 'UAH - Ukrainian Hryvnia', value: 'UAH', symbol: '₴' },
  { label: 'UGX - Ugandan Shilling', value: 'UGX', symbol: 'USh' },
  { label: 'UYU - Uruguayan Peso', value: 'UYU', symbol: '$' },
  { label: 'UZS - Uzbekistani Som', value: 'UZS', symbol: 'лв' },
  { label: 'VES - Venezuelan Bolívar', value: 'VES', symbol: 'Bs.' },
  { label: 'VND - Vietnamese Đồng', value: 'VND', symbol: '₫' },
  { label: 'VUV - Vanuatu Vatu', value: 'VUV', symbol: 'VT' },
  { label: 'WST - Samoan Tala', value: 'WST', symbol: 'T' },
  { label: 'XAF - Central African CFA Franc', value: 'XAF', symbol: 'FCFA' },
  { label: 'XCD - East Caribbean Dollar', value: 'XCD', symbol: '$' },
  { label: 'XOF - West African CFA Franc', value: 'XOF', symbol: 'CFA' },
  { label: 'XPF - CFP Franc', value: 'XPF', symbol: '₣' },
  { label: 'YER - Yemeni Rial', value: 'YER', symbol: '﷼' },
  { label: 'ZAR - South African Rand', value: 'ZAR', symbol: 'R' },
  { label: 'ZMW - Zambian Kwacha', value: 'ZMW', symbol: 'ZK' },
  { label: 'ZWL - Zimbabwean Dollar', value: 'ZWL', symbol: '$' }
];



const getCurrencySymbol = (currencyValue) => {
  const currency = currencies.find((currency) => currency.value === currencyValue);
  return currency ? currency.symbol : null;
};

export { currencies, getCurrencySymbol };
