import { gql } from "@apollo/client";

const NEGOTIATE_PRICE = gql`
  mutation inviteToCampaign(
    $userId: ID!
    $campaignId: ID!
    $influencerId: ID!
    $myPrice: Int!
    $brandQuoteType: String!
  ) {
    brandNegotiatePrice(
      userId: $userId
      campaignId: $campaignId
      myPrice: $myPrice
      brandQuoteType: $brandQuoteType
      influencerId: $influencerId
    )
  }
`;

export default NEGOTIATE_PRICE;
