import { gql } from '@apollo/client';


const GET_MY_CAMPAIGNS = gql`
  query getMyCampaigns($userId: ID!) {
    getMyCampaigns(userId: $userId) {
      id
      name
      coverPic
      media
      campaignType
      status
      user {
        brandName
        }
    }
  }`

  export default GET_MY_CAMPAIGNS;