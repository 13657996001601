import { gql } from "@apollo/client";

const ACCEPT_OFFER = gql`
  mutation brandAcceptContract(
    $contractId: ID!
    $influencerId: ID!
    $finalFee: Int!
  ) {
    brandAcceptContract(
      contractId: $contractId
      influencerId: $influencerId
      finalFee: $finalFee
    )
  }
`;

export default ACCEPT_OFFER;
