import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

export default function PopupNotification({ open, notificationText, type }) {
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000}>
        <Alert severity={type}>{notificationText}</Alert>
      </Snackbar>
    </div>
  );
}
