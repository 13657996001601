import React, { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, Grid, Modal, Typography } from "@mui/material";
import useStyles from "./styles";
//import GET_MY_CAMPAIGNS from "../../../graphql/queries/getMyCampaigns";
//import { useQuery } from "@apollo/client";

const CampaignGallery = (props) => {
  const { campaignId } = props;
  const classes = useStyles();
  const [open, setOpen] = useState();
  const [clickedItem, setClickedItem] = useState(null);
  function handleGalleryItemClick(item) {
    setClickedItem({ path: item, type: "img" });
    setOpen(true);
  }

  return (
    <Grid item xs={10}>
      <Typography
        variant="h5"
        align="center"
        sx={{ marginTop: 5, marginBottom: 5 }}
      >
        {" "}
        Campaign Gallery{" "}
      </Typography>
      {/*<ImageList
        sx={{ minWidth: 200, maxHeight: itemData.length * 164 }}
        cols={3}
      >
        {itemData.map((item) => (
          <ImageListItem
            key={item.img}
            onClick={(e) =>
              handleGalleryItemClick(e.target.getAttribute("src"))
            }
          >
            <img
              src={item.img}
              srcSet={item.img}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>*/}
      {clickedItem !== null && (
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.imageModal}>
            {clickedItem.type === "img" ? (
              <img src={clickedItem?.path} alt="gallery-pic-zoomed" />
            ) : (
              <></>
            )}
          </Box>
        </Modal>
      )}
    </Grid>
  );
};

const itemData = [
  {
    img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
    title: "Breakfast",
  },
  {
    img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
    title: "Burger",
  },
  {
    img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
    title: "Camera",
  },
  {
    img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
    title: "Coffee",
  },
  {
    img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
    title: "Hats",
  },
  {
    img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
    title: "Honey",
  },
  {
    img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
    title: "Basketball",
  },
  {
    img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
    title: "Fern",
  },
  {
    img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
    title: "Mushrooms",
  },
  {
    img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
    title: "Tomato basil",
  },
  {
    img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
    title: "Sea star",
  },
  {
    img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
    title: "Bike",
  },
];

export default CampaignGallery;
