import { gql } from "@apollo/client";

const GET_INVITED_INFLUENCERS = gql`
  query getInvitedInfluencers($campaignId: ID!) {
    getInvitedInfluencers(campaignId: $campaignId) {
      id
      influencerQuote
      influencerQuoteType     
      brandQuote
      brandQuoteType
      currentFlow
      currentNegotiator
      finalFee
      platformFee
      description
      contractStatus
      customContract
      influencer {
        id
        name
        image
      }
    }
  }
`;

export default GET_INVITED_INFLUENCERS;
