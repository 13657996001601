import { gql } from "@apollo/client";

const INFLUENCER_GALLERY_LISTING = gql`
    query getInfluencersForGallery(
        $userId: ID!
        $orderBy: String!
        $filterByCountry: [String!]
        $filterByCity: [String!]
        $filterByMedia: String
        $showInterested: Boolean
        $filterByCampaign: String
    ) {
        getInfluencersForGallery(
            userId: $userId
            orderBy: $orderBy
            filterByCountry: $filterByCountry
            filterByCity: $filterByCity
            filterByMedia: $filterByMedia
            showInterested: $showInterested
            filterByCampaign: $filterByCampaign
        ) {
            id
            name
            createdAt
            city
            country
            facebook
            image
            instagram
            instagramStat {
                id
                accountsEngaged
                accountsReached
                femaleRatio
                following
                growthRate
                link
                maleRatio
                topAgeRange
                topCity
                topCityPercentage
                topCountry
                topCountryPercentage
            }
            tiktok
            tiktokStat {
                id
                accountsEngaged
                femaleRatio
                following
                growthRate
                link
                maleRatio
                topAgeRange
                topCity
                topCityPercentage
                topCountry
                topCountryPercentage
                videoViews
            }
            userGalleries {
                id
                item
            }
            campaignResults {
                totalReach
                tiktokViews
            }
            website
            youtube
        }
    }
`;

export default INFLUENCER_GALLERY_LISTING;
