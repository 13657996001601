import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import CampaignDetailBanner from "./campaignDetailBanner";
import CampaignGallery from "./campaignGallery";
import useStyles from "./styles";

const PendingCampaign = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [defaultContract, setDefaultContract] = useState();
  const [campaignContract, setCampaignContract] = useState();
  const [campaign, setCampaign] = useState(null);
  const loadContract = async () => {
    await fetch(
      "https://authentic.lon1.cdn.digitaloceanspaces.com/authentic_default_contract.txt"
    ).then((r) => {
      r.text().then((d) => {
        setDefaultContract(d);
      });
    });
  };


  const loadCampaignContract = async () => {
    await fetch(
      campaign?.contract
    ).then((r) => {
      r.text().then((d) => {
        setCampaignContract(d);
      });
    });
  };

  useEffect(() => {
    loadContract();
  }, []);
  
  useEffect(() => {
    loadCampaignContract();
  }, [campaign]);

  return (
    <Grid
      item
      xs={12}
      container
      className={classes.mainGrid}
      justifyContent="center"
    >
      <CampaignDetailBanner setCampaign={setCampaign} campaignId={id} interestedCount={0} />
      <CampaignGallery campaignId={id} />
      <div style={{width: "100%"}} dangerouslySetInnerHTML={ {__html: defaultContract} || { __html: 'Loading...' }} />
      { campaignContract && (
        <div style={{width: "100%"}}>
        <b>
          <br/>
          Additional Clauses by brand
        </b>
        <div dangerouslySetInnerHTML={ {__html: campaignContract} || { __html: 'Loading...' }} />
        </div>
        ) 
      }
    </Grid>
  );
};

export default PendingCampaign;
