import React, { useState } from "react";
import TIKTOK_ICON from "../../../assets/icon/Tiktok.png";
import INSTAGRAM_ICON from "../../../assets/icon/Instagram.png";
import { Fab, Grid, IconButton, Typography } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Avatar from "@mui/material/Avatar";
import AccountBoxIcon from "@mui/icons-material/AccountBox";

import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import useStyles from "./styles";
import MediaContent from "../../common-components/mediaContent";
import CampaignInviteDialog from "../../common-components/campaignInviteDialog";
import integrationRedirection from '../../sharedComponents/integrationRedirection'

const InfluencerCard = (props) => {
  const {
    influencer,
    selectedInfluencer,
    profilePic,
    originGallery = false,
    handleLike,
    isLiked,
  } = props;
  const classes = useStyles();
  const [openInvite, setOpenInvite] = useState(false);

  if (influencer.userGalleries?.length < 1 && !originGallery) return <></>;

  return (
    <Grid
      item
      xs={12}
      lg={3}
      md={3}
      container
      direction="row"
      className={classes.cardContainer}
    >
      <Grid item xs={12} container direction="row" sx={{ maxHeight: "120px" }}>
        {/* Profile Pic */}
        <Grid item xs sx={{ maxWidth: "110px !important", marginRight: "3px" }}>
          {profilePic ? (
            <img
              src={profilePic}
              alt=""
              className={classes.profilePicContainer}
            />
          ) : (
            <AccountBoxIcon className={classes.profilePicContainer} />
          )}
        </Grid>
        <Grid item xs sx={{ minWidth: "220px", marginLeft: "5px" }}>
          <Typography variant="body1">
            <b> {influencer.name} </b>
          </Typography>
          <Grid
            item
            xs
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">
              <b>Location:</b> {influencer.country},{" "}
              {influencer.city !== "not set" && influencer.city}
            </Typography>
            <Fab
              aria-label="like"
              size="small"
              sx={{ backgroundColor: "transparent", boxShadow: "none" }}
              onClick={handleLike}
            >
              {isLiked ? (
                <FavoriteIcon className={classes.liked} />
              ) : (
                <FavoriteBorderIcon className={classes.notLiked} />
              )}
            </Fab>
            {openInvite && (
              <CampaignInviteDialog
                open={openInvite}
                setOpen={() => setOpenInvite(false)}
                campaignId={1}
                influencerId={influencer.id}
                campaignType="paid"
              />
            )}
          </Grid>
          <Grid
            item
            xs
            container
            direction="row"
            justifyContent="space-between"
          >
            <Grid item xs>
              {influencer.instagramStat?.id && (
                <>
                  <IconButton
                    aria-label="instagram"
                    onClick={() => integrationRedirection({userName: influencer?.instagram, integrationType: 'instagram'})}
                    sx={{ p: "2px" }}
                  >
                    <Avatar
                      src={INSTAGRAM_ICON}
                      sx={{ m: 0, width: 15, height: 15 }}
                    />
                  </IconButton>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "0.75rem !important", fontWeight: 500 }}
                  >
                    Followers:&emsp;
                    {influencer.instagramStat.following || "-"}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs>
              {influencer.tiktokStat?.id && (
                <>
                  <IconButton
                    aria-label="tiktok"
                    onClick={() => integrationRedirection({userName: influencer?.tiktok, integrationType: 'tiktok'})}
                    sx={{ p: "2px" }}
                  >
                    <Avatar
                      src={TIKTOK_ICON}
                      sx={{ m: 0, width: 15, height: 15 }}
                    />
                  </IconButton>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "0.75rem !important", fontWeight: 500 }}
                  >
                    {" "}
                    Followers: {influencer.tiktokStat.following || "-"}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {influencer?.userGalleries?.length > 0 && (
        <Grid
          item
          xs={12}
          onClick={() => selectedInfluencer()}
          sx={{ maxHeight: "620px" }}
        >
          <CarouselProvider
            isPlaying={true}
            naturalSlideWidth={400}
            naturalSlideHeight={600}
            totalSlides={influencer?.userGalleries?.length}
          >
            <Slider>
              {influencer?.userGalleries?.map((item, index) => {
                return (
                  <Slide index={index} key={item.id}>
                    <MediaContent
                      mediaSource={item?.item}
                      width="408px"
                      height="564px"
                    />
                  </Slide>
                );
              })}
            </Slider>
          </CarouselProvider>
        </Grid>
      )}
    </Grid>
  );
};
export default InfluencerCard;
