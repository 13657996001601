import { gql } from "@apollo/client";

const GET_GRAPHS = gql`
  query getGraphs {
    getGraphs {
      id
      name
      published
      createdAt
    }
  }
`;

export default GET_GRAPHS;
