import { gql } from "@apollo/client";

const GET_BRAND_CHATROOMS = gql`
  query getBrandChatrooms($userId: ID!) {
    getBrandChatrooms(userId: $userId) {
      id
      campaignId
      active
      campaign {
        name
        campaignType
      }
      lastMessage {
        text
        createdAt
      }
      participiantId
      brand {
        id
        name
        image
      }
    }
  }
`;

export default GET_BRAND_CHATROOMS;
