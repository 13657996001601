import { gql } from "@apollo/client";

const GET_SURVEY_INFLUENCERS = gql`
  query brandGetSurveyInfluencers($surveyId: ID!, $answer: String!) {
    brandGetSurveyInfluencers(surveyId: $surveyId, answer: $answer) {
      id
      name
      image
      city
      country
      instagram
      instagramStat {
        id
        accountsEngaged
        accountsReached
        following
      }
      tiktok
      tiktokStat {
        id
        accountsEngaged
        following
        videoViews
      }
      userGalleries {
        id
        item
      }
    }
  }
`;

export default GET_SURVEY_INFLUENCERS;
