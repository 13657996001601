import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  circularLoader: {
    width: "100vw",
    height: "100vh",
  },
});

export const CircularLoader = ({ title = "" }) => {
  const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      container
      alignItems="center"
      justifyContent="center"
      className={classes.circularLoader}
    >
      <CircularProgress sx={{ color: "#FFA3C7" }} />
      <Typography variant="h5" sx={{ color: "#FFA3C7" }}>
        &emsp; {title}
      </Typography>
    </Grid>
  );
};
