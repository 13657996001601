import { gql } from "@apollo/client";

const GET_MY_SURVEYS = gql`
  query getMySurveys($userId: ID!) {
    getMySurveys(userId: $userId) {
      id
      coverImage
      question
      answers
      surveyAnswers {
        id
        answer
        user {
          id
          image
        }
      }
      updatedAt
    }
  }
`;

export default GET_MY_SURVEYS;
