import React from "react";
import { Grid } from "@mui/material";
import UserBanner from "./userBanner";
import ArchiveCampaigns from "../ArchiveCampaigns";

const Profile = () => {
  const currentUser = localStorage.getItem("currentUser");
  return (
    <>
      <Grid item xs container justifyContent="center">
        <UserBanner user={currentUser} />
      </Grid>

      <Grid item xs={12} margin={6}>
        <ArchiveCampaigns />
      </Grid>
    </>
  );
};

export default Profile;
