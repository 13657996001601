import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import useStyles from "./styles";
import { useQuery } from "@apollo/client";
import GET_MY_CAMPAIGNS from "../../graphql/queries/getMyCampaigns";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

const campaignPage = {
  Pending: "/pendingCampaign/",
  Approved: "/campaignsApproved/",
  Rejected: "/pendingCampaign/",
  In_Progress: "/campaignsDetail/",
  Invoiced: "/campaignsApproved/",
  Finished: "/campaignsDetail/",
  0: "/pendingCampaign/",
  1: "/campaignsApproved/",
  2: "/pendingCampaign/",
  3: "/campaignsDetail/",
  4: "/campaignsApproved/",
  4: "/campaignsDetail/",
};
const campaignStatus = {
  Pending: "Pending",
  Approved: "Approved",
  Rejected: "Rejected",
  In_Progress: "In-Progress",
  Invoiced: "Approved",
  0: "Pending",
  1: "Approved",
  2: "Rejected",
  3: "In-Progress",
  4: "Approved",
};
const statusColor = {
  Pending: "#f9ca24",
  Approved: "#6ab04c",
  Rejected: "#eb3b5a",
  In_Progress: "#FFA3C7",
  Invoiced: "#6ab04c",
  0: "#f9ca24",
  1: "#6ab04c",
  2: "#eb3b5a",
  3: "#FFA3C7",
  4: "#6ab04c",
};

const campaignMedia = {
  Instagram: "Instagram ",
  TikTok: "TikTok ",
};
const campaignTypeIcon = {
  Paid: (
    <SvgIcon
      viewBox="0 0 512 512"
      sx={{
        color: "#fff",
        backgroundColor: "#EF2E82",
        padding: "4px 4px 4px 8px",
        borderRadius: 2,
        width: "2rem",
        height: "2rem",
      }}
    >
      <path d="M144 160.4c0-35.5 28.8-64.4 64.4-64.4c6.9 0 13.8 1.1 20.4 3.3l81.2 27.1c16.8 5.6 34.9-3.5 40.5-20.2s-3.5-34.9-20.2-40.5L249 38.6c-13.1-4.4-26.8-6.6-40.6-6.6C137.5 32 80 89.5 80 160.4V224H64c-17.7 0-32 14.3-32 32s14.3 32 32 32H80v44.5c0 17.4-4.7 34.5-13.7 49.4L36.6 431.5c-5.9 9.9-6.1 22.2-.4 32.2S52.5 480 64 480H320c17.7 0 32-14.3 32-32s-14.3-32-32-32H120.5l.7-1.1C136.1 390 144 361.5 144 332.5V288H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H144V160.4z" />
    </SvgIcon>
  ),
  Gifted: (
    <SvgIcon
      viewBox="0 0 512 512"
      sx={{
        color: "#fff",
        backgroundColor: "#EF2E82",
        padding: 1,
        borderRadius: 2,
        width: "2rem",
        height: "2rem",
      }}
    >
      <path d="M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z" />
    </SvgIcon>
  ),
};


const Campaigns = () => {
  const classes = useStyles(theme);

  const [search, setSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [allCampaigns, setAllCampaigns] = useState([]);

  const { data: campaigns } = useQuery(GET_MY_CAMPAIGNS, {
    fetchPolicy: "network-only",
    variables: {
      userId: localStorage.getItem("currentUser"),
    },
    onCompleted: (res) => {
      setAllCampaigns(res.getMyCampaigns);
    },
  });

  return (
    <Grid item xs className={classes.mainGrid}>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        className={classes.forceCenter}
      >

        <Box className={classes.banner} />
        <div className={classes.parenDiv} >
          <Typography
            variant="h5"
            sx={{ fontWeight: 500, color: "#FFA3C7", paddingLeft: "10px", justifyContent:"flex-start" }}
          >
            Campaigns
          </Typography>
          
          <Grid item xs container justifyContent="flex-end">
            <TextField
              id="search-campaign"
              label="Search Campaign"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon
                      sx={{ color: "#FFA3C7", width: "1.4rem", height: "1.4rem" }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              onChange={(e) => setSearch(e.target.value)}
              className={classes.filterWidth}
            />

          <FormControl sx={{ marginTop: 2, width: "290px" }}>
            <InputLabel id="demo-simple-select-label">Select Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Campaign"
              variant="standard"
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"in_progress"}>Started</MenuItem>
              <MenuItem value={"approved"}>Approved</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        </div>
      </Grid>
      
      <Grid
        item
        xs={12}
        container
        sx={{ paddingLeft: 1, paddingRight: 2 }}
        justifyContent="flex-start"
        className={classes.forceCenter}
      >
        <Link to="/addCampaign" className={classes.addCampaignLink}>
          + Add New Campaign
        </Link>
        {allCampaigns.length > 0 &&
            allCampaigns
                .filter((campaign) => {
                  // If no status is selected, show all campaigns
                  if (selectedStatus === "all" || selectedStatus === "") {
                    return true;
                  }
                  // Otherwise, only show campaigns with the selected status
                  return campaign.status.toLowerCase() === selectedStatus;
                })
                .filter((campaign) => {
                  // If no search term is entered, show all campaigns
                  if (search === "") {
                    return true;
                  }
                  // Otherwise, only show campaigns whose name includes the search term
                  return campaign.name.toLowerCase().includes(search.toLowerCase());
                })
                .map((campaign) => (
            <Link
              to={campaignPage[campaign.status] + campaign?.id}
              className={classes.campaignLink}
              key={campaign.status + "-campaign-" + campaign?.id}
            >
              <Paper
                elevation={1}
                className={classes.campaignCard}
                sx={{ backgroundImage: `url(${campaign?.coverPic})`, backgroundPosition: "top" }}
              >
                <Grid item xs={12} container sx={{ height: 350 }}>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="flex-start"
                    justifyContent="space-between"
                    sx={{ padding: "10px 10px 0px 10px", height: "350px" }}
                  >
                    <Typography
                      variant="body1"
                      className={classes.campaignStatus}
                      sx={{ backgroundColor: statusColor[campaign.status] }}
                    >
                      {campaignStatus[campaign.status]}
                    </Typography>
                    {campaignTypeIcon[campaign.campaignType]}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="flex-end"
                    className={classes.campaignBottomText}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 900, width: "100%" }}
                    >
                      {campaign.user && campaign.user.brandName ? `${campaign.user.brandName} - ${campaign.name}` : campaign.name}
                    </Typography>
                    {/* <Typography
                      variant="body1"
                      sx={{ fontWeight: 500, width: "100%" }}
                    >
                      {campaign.members.length} Influencers Interested
                    </Typography> */}
                    <Typography variant="subtitle1">
                      {campaign.media === "All"
                        ? "Instagram & TikTok "
                        : campaignMedia[campaign.media]}
                      Campaign
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Link>
          ))}
      </Grid>
    </Grid>
  );
};

export default Campaigns;
