import { createStyles, makeStyles } from "@mui/styles";

const styles = makeStyles((theme) =>
  createStyles({
    cardContainer: {
      maxWidth: 420,
      maxHeight: 745,
      padding: 5,
      marginBottom: 15,
      backgroundColor: "#fff",
      borderRadius: 10,
    },
    profilePicContainer: {
      minWidth: 110,
      maxWidth: 110,
      minHeight: 110,
      maxHeight: 110,
      borderRadius: 10,
      margin: 3,
    },
    liked: {
      "&.MuiSvgIcon-root": {
        color: "#e83e8c",
        fontSize: 35,
      },
    },
    notLiked: {
      "&.MuiSvgIcon-root": {
        fontSize: 35,
      },
    },
  })
);

export default styles;
