import { createStyles, makeStyles } from "@mui/styles";
import { createBreakpoints } from "@mui/system";
import CampaignBanner from "../../assets/campaigns-2.jpeg"

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 576,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

const styles = makeStyles((theme) =>
  createStyles({
    mainGrid: {
      maxWidth: "calc(100vw - 64px)!important",
      padding: "20px 5px 10px 20px",
    },

    forceCenter: {
      [breakpoints.down("sm")]: {
        justifyContent: "center !important",
      },
    },

    filterWidth: {
      minWidth: "290px !important",
      marginRight: " 10px !important",
      "& input": {
        padding: "12px 14px",
      },
      "& fieldset": {
        height: 45,
      },
    },

    addCampaignLink: {
      width: 350,
      height: 450,
      paddingTop: 200,
      margin: "30px 10px 30px 10px",
      textAlign: "center",
      border: "3px dashed #FFA3C7 !important",
      borderRadius: "20px !important",
      color: "#FFA3C7",
      textDecoration: "none",
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#FFA3C7",
        textDecoration: "none",
        border: "3px dashed #FFF !important",
      },
    },

    campaignCard: {
      width: 350,
      height: 450,
      margin: "30px 10px 30px 10px",
      borderRadius: "20px !important",
      backgroundPosition: "center",
      backgroundSize: "cover",
      "&:hover": {
        boxShadow:
          "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important",
      },
      [breakpoints.down("sm")]: {
        width: 260,
      },
    },

    campaignLink: {
      color: "#FFF",
      textDecoration: "none",
      margin: 0,
      "&:hover": {
        textDecoration: "none",
        color: "#fff",
      },
    },

    campaignStatus: {
      color: "#fff",
      padding: "3px 10px 3px 10px",
      borderRadius: 10,
    },

    campaignBottomText: {
      padding: "0px 10px 10px 10px",
      color: "#fff",
      height: "100px",
      background:
        "linear-gradient(360deg, rgba(0,0,0,1) 10%, rgba(0,0,0,0.5928965336134453) 70%, rgba(0,0,0,0) 100%)",
    },

    disabledLink: {
      pointerEvents: "none",
      textDecoration: "none",
    },

    formControl: {
      margin: 2,
      width: 300,
    },
    indeterminateColor: {
      color: "#f50057",
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    banner: {
      height: "25vh",
      padding: "5px 5px 5px 5px",
      width: "99%",
      backgroundImage: `url(${CampaignBanner})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      color: "#2d3436",
      marginBottom: 40,
      objectFit:"contain"
    },
    parenDiv: {
      display: "flex", 
      width: "100%",
    },
  })
);

export default styles;
