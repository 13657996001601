import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  editProfileBtn: {
    width: 35,
    height: 35,
    position: "relative !important",
    top: -260,
    right: -140,
    backgroundColor: "#EE2F82 !important",
    color: "#fff !important",
  },

  banner: {
    height: "30vh",
    minHeight: "350px",
    padding: "10px 5px",
    backgroundImage: `url(https://images.unsplash.com/photo-1570475735025-6cd1cd5c779d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8)`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    color: "#2d3436",
    marginBottom: 40,
  },

  avatar: {
    width: "150px !important",
    height: "150px !important",
    boxShadow: "rgba(0, 0, 0, 0.6) 0px 1px 4px",
    pointerEvents: "all",
    "& :hover": {
      cursor: "pointer",
    },
  },

  socialIcon: {
    padding: 4,
  },

  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "65vw !important",
    maxWidth: "500px !important",
    height: "700px",
    backgroundColor: "#fff",
    border: "2px solid #fff",
    borderRadius: "15px !important",
    padding: 50,
    overflowX: "hidden",
    overflowY: "scroll",
  },
});

export default useStyles;
