import { createStyles, makeStyles } from "@mui/styles";

const styles = makeStyles((theme) =>
  createStyles({
    imageModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "70vw",
      backgroundColor: '#fff',
      border: '2px solid #000',
      padding: "6px 6px 0px 6px",
      '& img': {
        margin: "auto"
      }
    },
  })
);

export default styles;
