import {
  Grid,
  Box,
  Modal,
  Avatar,
  List,
  ListItemText,
  ListItemAvatar,
  Typography,
  Button,
} from "@mui/material";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import TIKTOK_ICON from "../../assets/icon/Tiktok.png";
import INSTAGRAM_ICON from "../../assets/icon/Instagram.png";
import React, { useMemo, useState } from "react";
import MediaContent from "./mediaContent";
import {useMutation, useQuery} from "@apollo/client";
import GET_INFLUENCER_FOR_POPUP from "../../graphql/queries/getInfluencerForPopup";
import GET_CAMPAIGN_DETAILS from "../../graphql/queries/getCampaignDetails";
import integrationRedirection from '../sharedComponents/integrationRedirection'
import INVITE_TO_CAMPAIGN from "../../graphql/mutations/inviteToCampaign";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  height: 950,
  backgroundColor: "#FFF",
  boxShadow: 24,
  borderRadius: "10px",
};

const InfluencerPopup = ({
  influencerId,
  nextInfluencer,
  closePopup,
  campaignId,
  originCampaign,
}) => {
  useMemo(
    () =>
      window.addEventListener(
        "keydown",
        (function (canMove) {
          return function (event) {
            if (!canMove) return false;
            canMove = false;
            setTimeout(function () {
              canMove = true;
            }, 500);
            switch (event.key) {
              case "ArrowLeft":
                console.log(
                  document.getElementById(`slide-back-button-${influencerId}`)
                );
                document
                  .getElementById(`slide-back-button-${influencerId}`)
                  .click();
                break;
              case "ArrowRight":
                document
                  .getElementById(`slide-forward-button-${influencerId}`)
                  .click();
                break;
              default:
                break;
            }
          };
        })(true),
        false
      ),
    [influencerId]
  );
  const [open, setOpen] = useState(false);
  const [campaign, setCampaign] = useState({});
  const [inviteToCampaign] = useMutation(INVITE_TO_CAMPAIGN);
  const { data: influencer, loading } = useQuery(GET_INFLUENCER_FOR_POPUP, {
    variables: {
      userId: influencerId,
    },
    onCompleted: () => {
      setOpen(true);
    },
  });
  useQuery(GET_CAMPAIGN_DETAILS, {
    variables: {
      campaignId: campaignId,
    },
    onCompleted: (res) => {
      setCampaign(res.getCampaignDetails);
    },
  });
  const handleModalClose = () => {
    setOpen(false);
    closePopup();
  };

  function handleInvite() {
    inviteToCampaign({
      variables: {
        campaignId: campaignId,
        influencerId: [influencerId],
      },
      refetchQueries: ["getInvitedInfluencers"],
    });
    closePopup()
  }


  if (loading) return <></>;
  return (
    <Modal
      open={open}
      onClose={() => handleModalClose()}
      aria-labelledby="gallery-modal"
    >
      <Box sx={modalStyle}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "#FFA3C7",
            px: 2,
            py: 1,
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "1rem" }}>
            <b>{influencer.getInfluencer.name}</b>
          </Typography>
          <Typography variant="subtitle1" sx={{ marginLeft: 2 }}>
            <b>Location:</b> {influencer.getInfluencer.country}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          sx={{ maxHeight: "120px", px: 2 }}
        >
          <Grid item>
            <Avatar
              sx={{
                width: 80,
                height: 80,
                backgroundColor: "#FFA3C7",
                marginRight: 2,
              }}
              alt={influencer.getInfluencer.name}
              src={influencer.getInfluencer.image}
              variant="rounded"
            />
          </Grid>
          <Grid item xs={8}>
            <List
              dense
              sx={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt="Instagram"
                  src={INSTAGRAM_ICON}
                  onClick={() => integrationRedirection({userName: influencer.getInfluencer?.instagram, integrationType: 'instagram'})}
                  sx={{ width: 30, height: 30 }}
                />
              </ListItemAvatar>
              <ListItemText
                id={influencer.getInfluencer.id}
                primary={"Following"}
                secondary={
                  influencer.getInfluencer.instagramStat?.following || "-"
                }
                primaryTypographyProps={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "0.7rem",
                }}
                secondaryTypographyProps={{
                  textAlign: "center",
                  color: "rgba(239, 46, 131, 1)",
                  fontSize: "1.0rem",
                }}
                sx={{ marginLeft: 2 }}
              />
              <ListItemText
                id={influencer.getInfluencer.id}
                primary={"Engagement"}
                secondary={
                  influencer.getInfluencer.instagramStat?.accountsEngaged || "-"
                }
                primaryTypographyProps={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "0.7rem",
                }}
                secondaryTypographyProps={{
                  textAlign: "center",
                  color: "rgba(239, 46, 131, 1)",
                  fontSize: "1.0rem",
                }}
                sx={{ marginLeft: 2 }}
              />
              <ListItemText
                id={influencer.getInfluencer.id}
                primary={"Reach"}
                secondary={
                  influencer.getInfluencer.instagramStat?.accountsReached || "-"
                }
                primaryTypographyProps={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "0.7rem",
                }}
                secondaryTypographyProps={{
                  textAlign: "center",
                  color: "rgba(239, 46, 131, 1)",
                  fontSize: "1.0rem",
                }}
                sx={{ marginLeft: 2 }}
              />
            </List>
            <List
              dense
              sx={{
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt="TikTok"
                  src={TIKTOK_ICON}
                  onClick={() => integrationRedirection({userName: influencer.getInfluencer?.tiktok, integrationType: 'tiktok'})}
                  sx={{ width: 30, height: 30 }}
                />
              </ListItemAvatar>
              <ListItemText
                id={influencer.getInfluencer.id}
                primary={"Following"}
                secondary={
                  influencer.getInfluencer.tiktokStat?.following || "-"
                }
                primaryTypographyProps={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "0.7rem",
                }}
                secondaryTypographyProps={{
                  textAlign: "center",
                  color: "rgba(239, 46, 131, 1)",
                  fontSize: "1.0rem",
                }}
                sx={{ marginLeft: 2 }}
              />
              <ListItemText
                id={influencer.getInfluencer.id}
                primary={"Engagement"}
                secondary={
                  influencer.getInfluencer.tiktokStat?.accountsEngaged || "-"
                }
                primaryTypographyProps={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "0.7rem",
                }}
                secondaryTypographyProps={{
                  textAlign: "center",
                  color: "rgba(239, 46, 131, 1)",
                  fontSize: "1.0rem",
                }}
                sx={{ marginLeft: 2 }}
              />
              <ListItemText
                id={influencer.getInfluencer.id}
                primary={"Views"}
                secondary={
                  influencer.getInfluencer.tiktokStat?.videoViews || "-"
                }
                primaryTypographyProps={{
                  textAlign: "center",
                  fontWeight: 700,
                  fontSize: "0.7rem",
                }}
                secondaryTypographyProps={{
                  textAlign: "center",
                  color: "rgba(239, 46, 131, 1)",
                  fontSize: "1.0rem",
                }}
                sx={{ marginLeft: 2 }}
              />
            </List>
          </Grid>
        </Grid>
        {!originCampaign && (
          <Grid
            item
            xs={12}
            sx={{ paddingLeft: 2, paddingRight: 2, marginTop: 1 }}
          >
            <Button
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "rgba(239, 46, 131, 1)",
                color: "#FFFFFF",
              }}
              disabled={!campaign?.id}
              onClick={handleInvite}
            >
              Invite
            </Button>
          </Grid>
        )}
        <Grid item xs={12} px={2}>
          {influencer.getInfluencer.userGalleries.length < 1 ? (
            <MediaContent mediaSource="https://i.ibb.co/JRd5wsd/not-available-icon-62ff1c150baaf1f6c92ec5edf36e4481526801d3a687c8755999b55d80fc94c1.gif" />
          ) : (
            <CarouselProvider
              infinite={true}
              naturalSlideWidth={480}
              naturalSlideHeight={700}
              totalSlides={influencer.getInfluencer.userGalleries.length}
              isPlaying={true}
            >
              <ButtonBack
                id={`slide-back-button-${influencerId}`}
                style={{
                  backgroundColor: "white",
                  borderWidth: 0,
                  marginTop: "10px",
                }}
              />
              <ButtonNext
                id={`slide-forward-button-${influencerId}`}
                style={{
                  backgroundColor: "white",
                  borderWidth: 0,
                  marginTop: "10px",
                }}
              />
              <Slider style={{ height: "80%", maxHeight: "650px", borderRadius: "15px" }}>
                {influencer.getInfluencer.userGalleries.map((item, index) => {
                  return (
                    <Slide index={index} key={item.id} >
                      <MediaContent
                        mediaSource={item?.item}
                        videoControls={true}
                        videoMuted={true}
                      />
                    </Slide>
                  );
                })}
              </Slider>
            </CarouselProvider>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};
export default InfluencerPopup;
