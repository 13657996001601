import PropTypes from "prop-types";
import { Box, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import ContractPopup from "./ContractPopup";
import CampaignComparisonChart from "./stats/campaignComparisonChart";

// ----------------------------------------------------------------------

const StyledProductImg = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
  objectPosition: "center top"
});

// ----------------------------------------------------------------------

NewPaidCampaignCard.propTypes = {
  product: PropTypes.object,
};

export default function NewPaidCampaignCard({ campaign }) {
  const { name, coverPic, campaignContracts, campaignType, contract  } = campaign;
  console.log(campaign);

  const [defaultContract, setDefaultContract] = useState("");

  const loadContract = async () => {
    await fetch(
      "https://authentic.lon1.cdn.digitaloceanspaces.com/authentic_default_contract.txt"
    ).then((r) => {
      r.text().then((d) => {
        setDefaultContract(d);
      });
    });
  };

  useEffect(() => {
    loadContract();
  }, [campaignContracts]);

  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        <Typography
          variant="subtitle1"
          sx={{
            zIndex: 9,
            bottom: 0,
            left: 0,
            position: "absolute",
            textTransform: "uppercase",
            color: "#FFF",
            padding: 1,
            background: "rgba(0, 0, 0, 0.7)",
            width: "100%",
          }}
        >
          {name}
        </Typography>
        <StyledProductImg alt={name} src={coverPic} />
      </Box>
      {campaignType === "Paid" && (
        <ContractPopup
          contract={defaultContract}
          campaignContracts={campaignContracts}
          campaignCustomContract={contract}
        />
      )}
    </Card>
  );
}
