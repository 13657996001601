import { useEffect, useState } from "react";
import { numberWithCommas } from "../../constants/functions";
import { Box, Button, Fab, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from "../CustomHooks/windowDimensions";
import "./Style.css";

function SideBar({
  state,
  close = () => {},
  list = {},
  handleHeart,
  campaignList,
  handleSubmit = () => {},
  handleDeselect = () => {},
}) {
  const [selectedCampaign, setSelectedCampaign] = useState("0");
  const { width } = useWindowDimensions();
  const [sortOrder, setSortOrder] = useState("newest");

  useEffect(() => {
    if (state) {
      if (width > 1015)
        document.getElementById("mySidenav").style.width = "35%";
      if (width < 1015)
        document.getElementById("mySidenav").style.width = "45%";
      if (width < 790) document.getElementById("mySidenav").style.width = "65%";
      if (width < 540)
        document.getElementById("mySidenav").style.width = "100%";
    } else {
      document.getElementById("mySidenav").style.width = "0";
    }
  }, [state, width]);

  let InfluencerList = Object?.values(list);
  let InfluencerTotalReach = 0;
  let InfluencerInstagramFollowing = 0;
  let InfluencerTiktokFollowing = 0;
  let InfluencerTikTokViews = 0;
  
  const influencerReach =(influencer) => {
    let totalReach = 0
    influencer?.campaignResults?.forEach((result) => {
      totalReach = totalReach + result.totalReach
    });
    return totalReach;
  }

  const influencerViews =(influencer) => {
    let totalTiktokViews = 0
    influencer?.campaignResults?.forEach((result) => {
      totalTiktokViews = totalTiktokViews + result.tiktokViews
    });
    return totalTiktokViews;
  }
  
  InfluencerList?.forEach((item) => {
    if (item?.instagramStat?.following) {
      let followers = item.instagramStat?.following;
      InfluencerInstagramFollowing =
        parseFloat(InfluencerInstagramFollowing) + parseFloat(followers);
    }
    if (item?.tiktokStat?.following) {
      let followers = item.tiktokStat?.following;
      InfluencerTiktokFollowing =
        parseFloat(InfluencerTiktokFollowing) + parseFloat(followers);
    }
    if (item?.campaignResults) {
      let reach = influencerReach(item);
      InfluencerTotalReach =
        parseFloat(InfluencerTotalReach) + parseFloat(reach);

      let views = influencerViews(item)
      InfluencerTikTokViews += parseFloat(views);

    }

  });
  const handleClose = () => {
    setSelectedCampaign("0");
    close();
  };
  const handleInvite = (campaignId) => {
    handleSubmit(campaignId);
    setSelectedCampaign("0");
    close();
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const sortedInfluencerList = [...InfluencerList].sort((a, b) => {
    if (sortOrder === "newest") {
      return b.id - a.id;
    } else {
      return a.id - b.id;
    }
  });

  return (
      <Grid
          item
          xs={12}
          container
          id="mySidenav"
          className="sidenav"
          alignItems="start"
      >
        <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            sx={{height: 40}}
        >
          {selectedCampaign !== "0" && (
              <Button
                  variant="contained"
                  onClick={() => handleInvite(selectedCampaign)}
                  disableElevation
                  sx={{height: 40, width: 200}}
              >
                Invite to Campaign
              </Button>
          )}
          <Button variant="contained" onClick={() => handleClose()}>
            &times;
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{padding: "5px 10px 5px 10px"}}>
            <label htmlFor="campaigns" className="dropdown-label">
              Campaign:
            </label>
            <br/>
            <select
                id="campaigns"
                name="campaigns"
                className="dropdown-list"
                onChange={(e) => setSelectedCampaign(e.target.value)}
            >
              <option value="0"> None</option>
              {campaignList?.map(
                  (campaign) =>
                      campaign.status === "Approved" && (
                          <option value={campaign.id} key={campaign.name}>
                            {campaign.name}
                          </option>
                      )
              )}
            </select>
          </Box>
        </Grid>
        <Grid
            item
            xs={12}
            container
            sx={{
              padding: 0.5,
              backgroundColor: "#EF2E83",
              color: "#ffffff",
              marginBottom: 1,
            }}
        >
          <Grid item xs={2} container direction="column" alignItems="center">
            <Typography variant="caption" display="block">
              Influencers
            </Typography>
            <Typography variant="caption" display="block">
              <b>{numberWithCommas(InfluencerList?.length) || 0}</b>
            </Typography>
          </Grid>
          <Grid item xs={2} container direction="column" alignItems="center">
            <Typography variant="caption"> Total Reach </Typography>
            <Typography variant="caption">
              <b>{numberWithCommas(InfluencerTotalReach)}</b>
            </Typography>
          </Grid>
          <Grid item xs={3} container direction="column" alignItems="center">
            <Typography variant="caption" style={{textAlign: "center"}}>
              Instagram Following
            </Typography>
            <Typography variant="caption" style={{textAlign: "center"}}>
              <b>{numberWithCommas(InfluencerInstagramFollowing)}</b>
            </Typography>
          </Grid>
          <Grid item xs={3} container direction="column" alignItems="center">
            <Typography variant="caption" style={{textAlign: "center"}}>
              Tiktok Following
            </Typography>
            <Typography variant="caption" style={{textAlign: "center"}}>
              <b>{numberWithCommas(InfluencerTiktokFollowing)}</b>
            </Typography>
          </Grid>
          <Grid item xs={2} container direction="column" alignItems="center">
            <Typography variant="caption"> Video Views </Typography>
            <Typography variant="caption">
              <b>{numberWithCommas(InfluencerTikTokViews)}</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
              variant="contained"
              onClick={() => handleDeselect()}
              disableElevation
              sx={{height: 30, width: 150}}
          >
            Deselect All
          </Button>
        </Grid>
        <Grid item xs={12} sx={{minHeight: "65vh"}}>
          <Box
              display="flex"
              justifyContent="flex-end"
              sx={{marginRight: "10px"}}
          >
            <select value={sortOrder} onChange={handleSortChange}>
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </Box>
          {sortedInfluencerList.map((influencer, index) => (
              <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  sx={{
                    padding: 2,
                    border: 1,
                    borderColor: "#ecf0f1",
                  }}
                  alignItems="center"
                  key={"influencer_no_" + index}
              >
                {/* Profile Pic */}
                <Grid item xs={3} lg={2}>
                  <img
                      src={
                          influencer?.image ||
                          "https://mir-s3-cdn-cf.behance.net/user/230/110659178842.5c1b604bb2cea.jpg"
                      }
                      alt="profile-pic"
                      className="profile-pic-container"
                  />
                </Grid>
                {/* Leyla harsumetails */}
                <Grid item xs={9}>
                  <Typography variant="body1">
                    <b> {influencer.influencer_name} </b>
                  </Typography>
                  <Grid
                      item
                      xs
                      container
                      alignItems="center"
                      justifyContent="space-between"
                  >
                    <Typography variant="caption">
                      <b>Location:</b> {influencer.location}
                    </Typography>
                    <Fab
                        aria-label="like"
                        size="small"
                        sx={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                          "&.MuiFab-root:hover": {
                            backgroundColor: "#EF2E83",
                            color: "#ffffff",
                          },
                        }}
                        onClick={(e) => handleHeart(e, influencer.id, influencer)}
                    >
                      <CloseIcon sx={{"&.MuiSvgIcon-root": {fontSize: 20}}}/>
                    </Fab>
                  </Grid>
                  <Grid item xs={12} container sx={{paddingTop: "5px"}}>
                    <Grid
                        item
                        xs={3}
                        container
                        direction="column"
                        alignItems="center"
                        sx={{borderLeft: 1}}
                    >
                      <Typography variant="caption"> Total Reach </Typography>
                      <Typography variant="caption">
                        <b>
                          {numberWithCommas(
                              influencerReach(influencer)
                          )}
                        </b>
                      </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        container
                        direction="column"
                        alignItems="center"
                        sx={{borderLeft: 1}}
                    >
                      <Typography variant="caption" style={{textAlign: "center"}}>
                        Instagram Following
                      </Typography>
                      <Typography variant="caption" style={{textAlign: "center"}}>
                        <b>
                          {numberWithCommas(
                              parseInt(influencer?.instagramStat?.following || 0)
                          )}
                        </b>
                      </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={3}
                        container
                        direction="column"
                        alignItems="center"
                        sx={{borderLeft: 1}}
                    >
                      <Typography variant="caption" style={{textAlign: "center"}}>
                        Tiktok Following
                      </Typography>
                      <Typography variant="caption" style={{textAlign: "center"}}>
                        <b>
                          {numberWithCommas(
                              parseInt(influencer?.tiktokStat?.following || 0)
                          )}
                        </b>
                      </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        container
                        direction="column"
                        alignItems="center"
                        sx={{borderLeft: 1, borderRight: 1}}
                    >
                      <Typography variant="caption"> Video Views </Typography>
                      <Typography variant="caption">
                        <b>
                          {influencer?.tiktokStat === null
                              ? 0
                              : numberWithCommas(influencerViews(influencer))}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
          ))}
        </Grid>
      </Grid>
  );
}

export default SideBar;
