import React, { useState } from "react";
import {
  Avatar,
  Grid,
  Button,
  Box,
  Paper,
  Link,
  FormControl,
  CssBaseline,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Typography,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import ProfilePicUploader from "./profilePicUploader";
import { useMutation, useQuery } from "@apollo/client";
import SIGNUP from "../../graphql/mutations/signup";
import GET_CAMPAIGN_CATEGORIES from "../../graphql/queries/getCampaignCategories";
import FINISH_SIGNUP from "../../graphql/mutations/finishSignup";

const countries = ["England", "Northern Ireland", "Scotland", "Wales"];
const cities = {
  England: [
    "Bath",
    "Birmingham",
    "Bradford",
    "Brighton and Hove",
    "Bristol",
    "Cambridge",
    "Canterbury",
    "Carlisle",
    "Chelmsford",
    "Chester",
    "Chichester",
    "Colchester",
    "Coventry",
    "Derby",
    "Doncaster",
    "Durham",
    "Ely",
    "Exeter",
    "Gloucester",
    "Hereford",
    "Kingston upon Hull",
    "Lancaster",
    "Leeds",
    "Leicester",
    "Lichfield",
    "Lincoln",
    "Liverpool",
    "London",
    "Manchester",
    "Milton Keynes",
    "Newcastle upon Tyne",
    "Norwich",
    "Nottingham",
    "Oxford",
    "Peterborough",
    "Plymouth",
    "Portsmouth",
    "Preston",
    "Ripon",
    "Salford",
    "Salisbury",
    "Sheffield",
    "Southampton",
    "Southend-on-Sea",
    "St Albans",
    "Stoke-on-Trent",
    "Sunderland",
    "Truro",
    "Wakefield",
    "Wells",
    "Westminster",
    "Winchester",
    "Wolverhampton",
    "Worcester",
    "York",
  ],
  "Northern Ireland": [
    "Armagh",
    "Bangor",
    "Belfast",
    "Lisburn",
    "Londonderry",
    "Newry",
  ],
  Scotland: [
    "Aberdeen",
    "Dundee",
    "Dunfermline",
    "Edinburgh",
    "Glasgow",
    "Inverness",
    "Perth",
    "Stirling",
  ],
  Wales: [
    "Bangor",
    "Cardiff",
    "Newport",
    "St Asaph (Llanelwy)",
    "St Davids",
    "Swansea",
    "Wrexham",
  ],
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://brands.authentic-influencers.com/">
        Authentic Influencers
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const theme = createTheme();

const collageData = [
  {
    img: "https://i.ibb.co/F5YnGNR/657667-EF-4381-4-DB7-9-B86-2-B0-A7850-A803.png",
    title: "influencer-img-1",
  },
  {
    img: "https://i.ibb.co/wQkDT8p/nail-kind-content.jpg",
    title: "influencer-img-2",
  },
  {
    img: "https://i.ibb.co/QHqH33L/68870422-372163797056198-5707664779837893847-n.jpg",
    title: "influencer-img-3",
  },
  {
    img: "https://i.ibb.co/FW8kMZf/Nina-Content-for-Mermade-Hair-2.jpg",
    title: "influencer-img-4",
  },
  {
    img: "https://i.ibb.co/m9MqCNY/polkadotqueen.jpg",
    title: "influencer-img-5",
  },
  {
    img: "https://i.ibb.co/vYnj2nf/natalieaston.jpg",
    title: "influencer-img-6",
  },
  {
    img: "https://i.ibb.co/TLTktkf/277453632-1037738147137237-5133846530842634878-n.jpg",
    title: "influencer-img-2",
  },
  {
    img: "https://i.ibb.co/p0S04dM/273809573-509320637278616-2052034274987818654-n.jpg",
    title: "influencer-img-4",
  },
];

const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
const Signup = () => {
  const [fields, setFields] = useState({
    userName: "",
    email: "",
    name: "",
    phone: "",
    password: "",
    facebook: "",
    instagram: "",
    tiktok: "",
    category_id: 0,
  });
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const navigate = useNavigate();
  const { data: categories } = useQuery(GET_CAMPAIGN_CATEGORIES);
  function disableIt() {
    return (
      fields.userName === "" ||
      fields.email === "" ||
      fields.name === "" ||
      fields.password === "" ||
      selectedCountry === "" ||
      selectedCity === "" ||
      profilePic === null ||
      !isEmail(fields.email)
    );
  }
  const [finishSignup] = useMutation(FINISH_SIGNUP, {
    onCompleted: () => {
      navigate("/");
    },
  });

  const [signup] = useMutation(SIGNUP, {
    onCompleted: (res) => {
      finishSignup({
        variables: {
          id: res.userRegister.authenticatable.id,
          brandName: fields.userName,
          name: fields.name,
          phone: fields.phone,
          country: selectedCountry,
          city: selectedCity,
          instagram: fields.instagram,
          tiktok: fields.tiktok,
          facebook: fields.facebook,
          image: profilePic,
          categories: [fields.category_id],
        },
      });
    },
  });

  function signUp() {
    signup({
      variables: {
        email: fields.email,
        password: fields.password,
        passwordConfirmation: fields.password,
      },
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid item xs={12} sm={5} md={3} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 3,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                backgroundColor: "transparent",
                width: 200,
                height: 60,
              }}
              variant="square"
            >
              <img src={logo} alt="authentic-influencers" width="200" />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ marginTop: 2 }}>
              Brand Dashboard
            </Typography>
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Brand Name"
                name="name"
                autoComplete="name"
                autoFocus
                onChange={(e) =>
                  setFields({ ...fields, userName: e.target.value })
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                type="email"
                onChange={(e) =>
                  setFields({ ...fields, email: e.target.value })
                }
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Your Name"
                name="name"
                autoComplete="name"
                autoFocus
                onChange={(e) => setFields({ ...fields, name: e.target.value })}
              />
              <TextField
                margin="normal"
                fullWidth
                id="phone"
                label="Your Contact Number"
                name="phone"
                autoComplete="phone"
                type="number"
                autoFocus
                onChange={(e) =>
                  setFields({ ...fields, phone: e.target.value })
                }
                sx={{
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                  "& input[type=number]::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                  },
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) =>
                  setFields({ ...fields, password: e.target.value })
                }
              />
              <TextField
                margin="normal"
                fullWidth
                id="facebook"
                label="Facebook"
                name="facebook"
                placeholder="authentic"
                autoComplete="facebook"
                autoFocus
                onChange={(e) =>
                  setFields({ ...fields, facebook: e.target.value })
                }
              />
              <TextField
                margin="normal"
                fullWidth
                id="instagram"
                label="Instagram Handle"
                name="instagram"
                placeholder="authentic"
                autoComplete="instagram"
                autoFocus
                onChange={(e) =>
                  setFields({ ...fields, instagram: e.target.value })
                }
              />
              <TextField
                margin="normal"
                fullWidth
                id="tiktok"
                placeholder="@authentic"
                label="TikTok Handle"
                name="tiktok"
                autoComplete="tiktok"
                autoFocus
                onChange={(e) =>
                  setFields({ ...fields, tiktok: e.target.value })
                }
              />
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Select Niche
                </InputLabel>
                <Select
                  labelId="campaign-category-select-label"
                  id="campaign-category-select"
                  label="Campaign Category"
                  value={fields.category_id}
                  variant="standard"
                  onChange={(e) =>
                    setFields({ ...fields, category_id: e.target.value })
                  }
                >
                  {categories?.getCampaignCategories?.map((category) => (
                    <MenuItem
                      value={category.id}
                      key={category.id + "category-type" + category.id}
                    >
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel id="country-select-label">
                  Select Country / Region
                </InputLabel>
                <Select
                  labelId="country-select-label"
                  id="country-select"
                  label="Country / Region"
                  variant="standard"
                  onChange={(e) => setSelectedCountry(e.target.value)}
                >
                  {countries.map((country) => (
                    <MenuItem value={country}>{country}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedCountry && (
                <FormControl fullWidth sx={{ marginTop: 2 }}>
                  <InputLabel id="demo-simple-select-label">
                    Select City
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Campaign"
                    variant="standard"
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    {cities[selectedCountry].map((city) => (
                      <MenuItem value={city}>{city}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <ProfilePicUploader setPP={setProfilePic} />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => signUp()}
                disabled={disableIt()}
              >
                Sign Up
              </Button>
              <Grid container direction="column" alignItems="center">
                <Grid item xs>
                  <Link href="/login" variant="body2">
                    {"Already have an account? Sign In"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs sx={{ maxHeight: "100vh", overflowY: "scroll" }}>
          <ImageList variant="masonry" cols={3} gap={8}>
            {collageData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  src={`${item.img}?w=220&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=220&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Signup;
