import {
  Grid,
  FormControl,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import useWindowDimensions from "../CustomHooks/windowDimensions";
import { MenuProps, useStyles, options } from "./utils";
import { useQuery } from "@apollo/client";
import GET_PAID_CAMPAIGN from "../../graphql/queries/getPaidCampaigns";
import CampaignSelectDropdown from "./../sharedComponents/campaignSelectDropdown";
import BrandSelectDropdown from "../sharedComponents/brandSelectDropdown";
import GET_BRANDS from "../../graphql/queries/getBrands";
import PublishButton from '../sharedComponents/publishButton';
import UnpublishButton from "../sharedComponents/unpublishButton";
import BudgetInput from "../sharedComponents/budgetInput";

const CpeComparisonChart = (props) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [series, setSeries] = useState([]);
  const [updatedOptions, setUpdatedOptions] = useState({});
  const [selected, setSelected] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const { userRole, chart } = props;
  const [brands, setBrands] = useState([]);
  const currentUserId = localStorage.getItem('currentUser');
  const [selectedBrand, setSelectedBrand] = useState(currentUserId);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([])
  const budgetValue = localStorage.getItem('budget');
  const budgetTypeValue = localStorage.getItem('budgetType');
  const [budget, setBudget] = useState(budgetValue);
  const [budgetType, setBudgetType] = useState(budgetTypeValue);

  const { allCampaignsData, refetch } = useQuery(GET_PAID_CAMPAIGN, {
    variables: {
     userId: selectedBrand,
     userRole: userRole,
     graphId: chart?.id
    },
    onCompleted: (res) => {
      setCampaigns(res.getPaidCampaigns);
    }
  });

  const { data: allBrandsData } = useQuery(GET_BRANDS, {
    skip: userRole !== 'Admin',
    onCompleted: (response) => {
      if (userRole === 'Admin') {
        setBrands(response.getBrands);
      }
    },
  });

  function formatResult(result) {
    if (isNaN(result) || !isFinite(result)) {
      return 0;
    } else {
      return result.toFixed(2);
    }
  }

  useEffect(() => {
    let checkedCampaigns = campaigns?.filter((campaign) => selected.includes(campaign.name));
    setSelectedCampaignIds(checkedCampaigns.map((campaign => campaign.id)));
    let campaignResultData = {}

    if (checkedCampaigns?.length > 0) {
      for (let i = 0; i < checkedCampaigns.length; i++) {
        const campaign = checkedCampaigns[i];
        const key = campaign.name;
        let totalEngagement = 0;

        if (!campaignResultData[key]) {
          campaignResultData[key] = {
            campaignCPE: 0,
            totalEngagement: 0
          };
        }

        for (const currentResult of campaign.campaignResults) {
          totalEngagement += currentResult.instaLikes + currentResult.instaComments +
                             currentResult.tiktokLikes + currentResult.tiktokComments
        }

        campaignResultData[key].campaignCPE = formatResult(budget / totalEngagement)
        campaignResultData[key].totalEngagement = totalEngagement
        campaignResultData[key].startDate = campaign.startDate
        campaignResultData[key].endDate = campaign.endDate
      }
    }

    setUpdatedOptions({
      ...chartOptions,
      xaxis: {
        categories: checkedCampaigns?.map((campaign) => campaign?.name),
      },
    });

    setSeries([
      {
        name: "CPE",
        data: selected?.map((campaign) => {
          const currentCampaign = campaigns?.find(item => item.name == campaign)

          return {
            y: campaignResultData[campaign]?.campaignCPE || 0,
            x: campaign,
            cost: budget?.toLocaleString(),
            totalEngagement: campaignResultData[campaign]?.totalEngagement.toLocaleString(),
            startDate: campaignResultData[campaign]?.startDate.toLocaleString(),
            endDate: campaignResultData[campaign]?.endDate.toLocaleString(),
          }

        }),
        fillColor: "#EB8C87",
        strokeColor: "#C23829",
      },
    ]);
  }, [selected, budget]);

  useEffect(() => {
    if (selectedBrand) {
      refetch()
    }
  }, [selectedBrand, refetch])

  const chartOptions = {
    chart: {
      foreColor: "#fff",
      type: "bar",
    },
    toolbar: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            fontSize: "16px",
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
      },
      theme: 'dark',
      fillSeriesColor: true,
      custom: function({series, seriesIndex, dataPointIndex, w}) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return tooltipData(data)
      }
    },
    legend: {
      fontSize: "16px",
      position: "right",
      offsetY: 40,
      color: "white",
    },
    fill: {
      opacity: 1,
    },
  };

  const tooltipData = (tooltipData) => {
    let tooltipContent =
      '<div class="tooltip">' +
        '<div class="heading-tooltip">' +
          '<div style="">' + '<strong>' + tooltipData.x + '</strong>' + '</div>' +
        '</div>' +
        '<div class="data-tooltip-main">' +
          '<div style="data-value">CPE:  ' + '<strong>' + tooltipData?.y  + '</strong>' + '</div>' +
          '<div style="data-value">Start Date:  ' + '<strong>' + tooltipData?.startDate + '</strong>' + '</div>' +
          '<div style="data-value">End Date:  ' + '<strong>' + tooltipData?.endDate + '</strong>' + '</div>' +
          '<div style="data-value">Campaign Cost:  ' + '<strong>' + tooltipData?.cost + '</strong>' + '</div>' +
          '<div style="data-value">Total Engagement:  ' + '<strong>' + tooltipData?.totalEngagement + '</strong>' + '</div>' +
        '</div>' +
      '</div>'
    return tooltipContent;
  }

  const handleBrandChange = (event) => {
    const value = event.target.value;
    setSelectedBrand(value);
  }

  const handleSelectionChange = (selectedCampaigns) => {
    setSelected(selectedCampaigns);
  };

  const handleBudgetChange = (value) => {
    setBudget(value);
  };

  const handleBudgetTypeChange = (value) => {
    setBudgetType(value)
  }


  return (
    <Grid item xs container justifyContent="start">
      <Grid item xs={12} container justifyContent="space-between">
        <Typography variant="h5">
          Cost Per Engagement Comparison
          <span style={{ fontSize: "1rem", color: "#FFA3C7" }}>
            &emsp;(Lower is better)
          </span>
        </Typography>
        <BudgetInput onBudgetChange={handleBudgetChange} onBudgetTypeChange={handleBudgetTypeChange}/>
        <FormControl className={classes.formControl}>
          {userRole == "Admin" && (
            <BrandSelectDropdown
              labelId="brand-select-label"
              brands={brands}
              selectedBrand={selectedBrand}
              handleChange={handleBrandChange}
              />
            )
          }
          <div style={{display: "flex", alignItems: 'center', marginTop: 10}}>
            <CampaignSelectDropdown
              campaigns={campaigns}
              onSelectionChange={handleSelectionChange}
              classes={classes}
              MenuProps={MenuProps}
            />
            <div style={{justifyContent: 'space-between',  display: 'flex' }}>
              <div style={{ flex: 1, margin: 5 }}>
                <PublishButton
                  graphId={chart?.id}
                  userId={selectedBrand}
                  campaignIds={selectedCampaignIds}
                  userRole={userRole}
                />
              </div>
              <div style={{flex: 1, margin: 5}}>
                <UnpublishButton
                  graphId={chart?.id}
                  userId={selectedBrand}
                  campaignIds={selectedCampaignIds}
                  userRole={userRole}
                />
              </div>
            </div>
          </div>
        </FormControl>
      </Grid>
      <Chart
        options={updatedOptions}
        series={series}
        type="bar"
        height={height / 1.6}
        width={width - 300}
      />
    </Grid>
  );
};

export default CpeComparisonChart;
