import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const root = ReactDOM.createRoot(document.getElementById("root"));

const client = new ApolloClient({
  uri: "https://api.testauthentic.co.uk/graphql",
  // uri: "http://localhost:3000/graphql",
  // uri: "https://authenticusers.co/graphql",
  // uri: "https://shrouded-dusk-40789-82873da8bdd3.herokuapp.com/graphql",
  
  headers: {
    accessToken: localStorage.getItem("accessToken") || "",
    client: localStorage.getItem("client") || "",
    uid: localStorage.getItem("uid") || "",
  },
  cache: new InMemoryCache(),
});
root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>
);
