import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

import { useState } from "react";
import { useMutation } from "@apollo/client";
import DELETE_OFFER from "../../../graphql/mutations/brandDeleteContract";


const ConfirmDelete = ({deleteConfirmation, showDeleteConfirmation, deleteInfluencer, setDeleteInfluencer, contractId}) => {

  const [deleteOffer] = useMutation(DELETE_OFFER);

  const finsihDeleteConfirmation = () =>{
    showDeleteConfirmation(false);
    deleteOffer({
      variables: {contractId: contractId, influencerId: deleteInfluencer.id},
      refetchQueries: ["getInterestedInfluencers", "getInvitedInfluencers", "getInfluencersWithTasks"],
    });
    setDeleteInfluencer('')
  }

  const influencerName = () =>{
    return deleteInfluencer?.name
  }

return (
  ( deleteInfluencer && <Dialog onClose={() => showDeleteConfirmation(false)} open={deleteConfirmation}>
    <DialogTitle id="responsive-dialog-title">
        {`Are you sure you want to remove ${influencerName()} from this campaign?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="center">
          Are you sure you want to remove {influencerName()} from this campaign?
          <br />
          <b style={{ color: "red" }}>This action cannot be undone</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => showDeleteConfirmation(false)}>
          Cancel
        </Button>
        <Button onClick={() => finsihDeleteConfirmation()} autoFocus>
          Yes, Finish
        </Button>
      </DialogActions>
    </Dialog>
  )
)}

export default ConfirmDelete;
