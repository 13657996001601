import { gql } from "@apollo/client";

const GET_INFLUENCER_FOR_CHAT = gql`
  query getInfluencer($userId: ID!) {
    getInfluencer(userId: $userId) {
      id
      name
      image
    }
  }
`;

export default GET_INFLUENCER_FOR_CHAT;
