import React from "react";
import { styled } from "@mui/material/styles";
import { isMobile } from "react-device-detect";

const StyledImage = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
  objectPosition: "center top",
});
const StyledVideo = styled("video")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
  objectPosition: "center top",
});
const StyledCardMedia = styled("div")({
  position: "relative",
  paddingTop: "calc(100% * 3 / 4)",
});
// You need to pass the unit for width & height along with the numbers
function MediaContent({
  mediaSource,
  videoControls = false,
  videoMuted = true,
}) {
  const fileType = mediaSource.split(/[#?]/)[0].split(".").pop().trim();
  const videoTypes = ["mp4", "webm", "mov", "avi", "mkv", "quicktime"]; // Add more video extensions as needed
  const videoMimeTypes = ["video/mp4", "video/webm", "video/quicktime"]; // Add more video MIME types as needed

  function isVideo(fileType) {
    return videoTypes.includes(fileType) || videoMimeTypes.includes(fileType);
  }

  return (
    <StyledCardMedia
      style={{
        width: isMobile ? "320px" : "480px",
        height: isMobile ? "500px" : "600px",
        display: "flex",
        borderRadius: "15px",
      }}
    >
      {isVideo(fileType)  ? (
        <StyledVideo
          muted={videoMuted}
          autoPlay={true}
          loop={true}
          controls={videoControls}
          style={{borderRadius:"15px"}}
        >
          <source src={mediaSource} />
        </StyledVideo>
      ) : (
        <StyledImage src={mediaSource} alt="" style={{borderRadius:"15px"}}/>
      )}
    </StyledCardMedia>
  );
}

export default MediaContent;
