import { createStyles, makeStyles } from "@mui/styles";
import { createBreakpoints } from "@mui/system";
import CampaignBanner from "../../assets/metrics-banner.jpeg"

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 576,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
});

const styles = makeStyles((theme) =>
  createStyles({
    mainGrid: {
      maxWidth: "calc(100vw - 64px)!important",
      padding: "20px 10px 10px 20px",
    },

    forceCenter: {
      [breakpoints.down("sm")]: {
        justifyContent: "center !important",
      },
    },
    profilePicContainer: {
      minWidth: 110,
      maxWidth: 110,
      minHeight: 110,
      maxHeight: 110,
      borderRadius: 10,
      margin: 3,
    },

    banner: {
      height: "40vh",
      padding: "20px 120px 20px 20px",
      width: "100%",
      backgroundImage: `url(${CampaignBanner})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
      color: "#2d3436",
      marginBottom: 40,
      objectFit:"contain"
    },
  })
);

export default styles;
