import {
  Grid,
  Typography,
  InputLabel,
  Input,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Box,
  FormControl
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import useWindowDimensions from "../CustomHooks/windowDimensions";
import { useQuery } from "@apollo/client";
import { MenuProps, useStyles, options } from "./utils";
import GET_ALL_CAMPAIGNS from "../../graphql/queries/getAllCampaigns";
import CampaignSelectDropdown from "./../sharedComponents/campaignSelectDropdown";
import BrandSelectDropdown from "../sharedComponents/brandSelectDropdown";
import GET_BRANDS from "../../graphql/queries/getBrands";
import PublishButton from '../sharedComponents/publishButton';
import UnpublishButton from "../sharedComponents/unpublishButton";


const RoiChart = (props) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [series, setSeries] = useState();
  const [income, setIncome] = useState(50);
  const [investment, setInvestment] = useState(50);
  const [roi, setRoi] = useState(0);
  const [investmentGain, setInvestmentGain] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const { userRole, chart } = props;
  const [brands, setBrands] = useState([]);
  const currentUserId = localStorage.getItem('currentUser');
  const [selectedBrand, setSelectedBrand] = useState(currentUserId);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([])
  const [selected, setSelected] = useState([]);

  const { allCampaignsData, refetch} = useQuery(GET_ALL_CAMPAIGNS, {
    variables: {
     userId: selectedBrand,
     userRole: userRole,
     graphId: chart?.id
    },
    onCompleted: (res) => {
      setCampaigns(res.getAllCampaigns);
    }
  });

  const { data: allBrandsData } = useQuery(GET_BRANDS, {
    skip: userRole !== 'Admin',
    onCompleted: (response) => {
      if (userRole === 'Admin') {
        setBrands(response.getBrands);
      }
    },
  });

  const options = {
    labels: ["Investment", "Income Gain"],
    legend: {
      fontSize: "16px",
      labels: {
        colors: undefined,
        useSeriesColors: true
      },
      position: 'bottom'
    },
  };

  useEffect(() => {
    let updatedSeries = [investment, income]
    const updatedRoi = ((income - investment) * 100 )/ investment || 0
    let gain = (income - investment) || 0
    let checkedCampaigns = campaigns?.filter((campaign) => selected.includes(campaign.name));

    setRoi(updatedRoi.toFixed(2))
    setSeries(updatedSeries);
    setInvestmentGain(gain);
    setSelectedCampaignIds(checkedCampaigns.map((campaign => campaign.id)));

  }, [income, investment, selected]);

  useEffect(() => {
    if (selectedBrand) {
      refetch()
    }
  }, [selectedBrand, refetch])

  const handleBrandChange = (event) => {
    const value = event.target.value;
    setSelectedBrand(value);
  }

  const handleSelectionChange = (selectedCampaigns) => {
    setSelected(selectedCampaigns);
  };

  return (
    <Grid xs container justifyContent="center">
      <Grid item xs={11} container justifyContent="space-between">
        <Grid item xs={7} container direction="column" alignItems="flex-start">
          <Typography variant="h4" style={{marginBottom: "10px"}}>ROI Chart</Typography>
          <InputLabel id="investment-label" sx={{ color: "#FFF", fontSize: "1rem !important"}}>
            Investment
          </InputLabel>
          <Input
            placeholder="Add your initial investment"
            style={{backgroundColor: "#FFF", height: "48px", marginBottom: "5px", padding: "6px", textAlign: "left"}}
            onChange={(e) => setInvestment(parseInt(e.target.value))}
          />

          <InputLabel id="income-label" sx={{ color: "#FFF", fontSize: "1rem !important" }}>
            Income
          </InputLabel>
          <Input
            placeholder="Add sales figure"
            style={{backgroundColor: "#FFF", height: "48px", marginBottom: "5px", padding: "4px"}}
            onChange={(e) => setIncome(parseInt(e.target.value))}
          />
        </Grid>

        <Grid item xs={4} container direction="column" alignItems="flex-end">
          <Box sx={{ minWidth: 180, height: 90, marginBottom: '20px' }}>
            <FormControl fullWidth>
              {userRole == "Admin" && (
                <BrandSelectDropdown
                  labelId="brand-select-label"
                  brands={brands}
                  selectedBrand={selectedBrand}
                  handleChange={handleBrandChange}
                  />
                )
              }
              <div style={{display: "flex", alignItems: 'center', marginTop: 10}}>
                <CampaignSelectDropdown
                  campaigns={campaigns}
                  onSelectionChange={handleSelectionChange}
                  classes={classes}
                  MenuProps={MenuProps}
                />
                <div style={{justifyContent: 'space-between',  display: 'flex' }}>
                  <div style={{ flex: 1, margin: 5 }}>
                    <PublishButton
                      graphId={chart?.id}
                      userId={selectedBrand}
                      campaignIds={selectedCampaignIds}
                      userRole={userRole}
                    />
                  </div>
                  <div style={{flex: 1, margin: 5}}>
                    <UnpublishButton
                      graphId={chart?.id}
                      userId={selectedBrand}
                      campaignIds={selectedCampaignIds}
                      userRole={userRole}
                    />
                  </div>
                </div>
              </div>
            </FormControl>
          </Box>
          <TableContainer style={{marginTop: '20px', paddingRight: 'auto'}}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ color: "#FFF", fontSize: "1rem !important" }}>Investment Gain</TableCell>
                  <TableCell sx={{ color: "#FFF", fontSize: "1rem !important" }}>£ {investmentGain}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ color: "#FFF", fontSize: "1rem !important" }}>ROI</TableCell>
                  <TableCell sx={{ color: "#FFF", fontSize: "1rem !important" }}>{roi} %</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Chart
        options={options}
        series={series}
        type="donut"
        height={height / 1.9}
        width={width - 60}
      />
    </Grid>
  );
};

export default RoiChart;
