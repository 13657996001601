import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Grid, Alert, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/img/logo.png";
import { useMutation } from "@apollo/client";
import REQUEST_OTP from "../../graphql/mutations/requestOtp";
import RESET_PASSWORD from "../../graphql/mutations/reset_password";

const useStyles = makeStyles({
  addWishBtn: {
    backgroundColor: "#EF2E83",
    margin: "1px 4px",
    height: 36,
  },
  inputField: {
    padding: 5,
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    maxWidth: "678px !important",
    height: 300,
    maxHeight: "90vh !important",
    backgroundColor: "#fff",
    border: "2px solid #fff",
    borderRadius: "24px !important",
    padding: "20px",
  },
  OtpModalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    maxWidth: "678px !important",
    height: 400,
    maxHeight: "90vh !important",
    backgroundColor: "#fff",
    border: "2px solid #fff",
    borderRadius: "24px !important",
    padding: "20px",
  },
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://brands.authentic-influencers.com/">
        Authentic Influencers
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const collageData = [
  {
    img: "https://i.ibb.co/F5YnGNR/657667-EF-4381-4-DB7-9-B86-2-B0-A7850-A803.png",
    title: "influencer-img-1",
  },
  {
    img: "https://i.ibb.co/wQkDT8p/nail-kind-content.jpg",
    title: "influencer-img-2",
  },
  {
    img: "https://i.ibb.co/QHqH33L/68870422-372163797056198-5707664779837893847-n.jpg",
    title: "influencer-img-3",
  },
  {
    img: "https://i.ibb.co/FW8kMZf/Nina-Content-for-Mermade-Hair-2.jpg",
    title: "influencer-img-4",
  },
  {
    img: "https://i.ibb.co/m9MqCNY/polkadotqueen.jpg",
    title: "influencer-img-5",
  },
  {
    img: "https://i.ibb.co/vYnj2nf/natalieaston.jpg",
    title: "influencer-img-6",
  },
  {
    img: "https://i.ibb.co/TLTktkf/277453632-1037738147137237-5133846530842634878-n.jpg",
    title: "influencer-img-2",
  },
  {
    img: "https://i.ibb.co/p0S04dM/273809573-509320637278616-2052034274987818654-n.jpg",
    title: "influencer-img-4",
  },
];

const Login = ({ handleLogin }) => {
  const classes = useStyles();
  const [openResetModal, setOpenResetModal] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [forgottenAccountEmail, setForgottenAccountEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleForgetInput = (event) => {
    setForgottenAccountEmail(event.currentTarget.value);
  };

  const [generateOtp] = useMutation(REQUEST_OTP, {
    variables: {
      email: forgottenAccountEmail,
    },
    onCompleted: (res) => {
      setError(res.brandRequestOtp);
      setOpenOtpModal(false);
    },
  });

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    variables: {
      email: forgottenAccountEmail,
      otp: otp,
      password: password,
    },
    onCompleted: (res) => {
      if (res.brandResetPassword === "Password Reset Successfully") {
        setForgottenAccountEmail(false);
        setOtp("");
        setPassword("");
        setOpenOtpModal(false);
        setError(res.brandResetPassword);
      } else {
        setError(res.brandResetPassword);
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        {error && (
          <Snackbar
            open={error}
            autoHideDuration={3000}
            onClose={() => setError(null)}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {error}
            </Alert>
          </Snackbar>
        )}
        <Grid item xs={12} sm={5} md={3} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                m: 1,
                backgroundColor: "transparent",
                width: 200,
                height: 60,
              }}
              variant="square"
            >
              <img src={logo} alt="authentic-influencers" width="200" />
            </Avatar>
            <Typography component="h1" variant="h5">
              Brand Dashboard
            </Typography>
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleLogin(email, password)}
              >
                Sign In
              </Button>
              <Grid container direction="column" alignItems="center">
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => setOpenResetModal(true)}
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item xs>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs sx={{ maxHeight: "100vh", overflowY: "scroll" }}>
          <ImageList variant="masonry" cols={3} gap={8}>
            {collageData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  src={`${item.img}?w=220&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=220&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
      <Modal
        open={openResetModal}
        onClose={() => setOpenResetModal(false)}
        aria-labelledby="request-password-reset"
        sx={{ width: 320, margin: "auto" }}
      >
        <Grid
          item
          xs={12}
          container
          direction="column"
          className={classes.modalStyle}
          alignItems="center"
        >
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Request Reset OTP
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            id="forgotten-email"
            label="Enter Email"
            onChange={handleForgetInput}
            defaultValue=""
            className={classes.inputField}
          />
          <br />
          <Grid item xs container justifyContent="space-evenly">
            <Button
              variant="contained"
              onClick={generateOtp}
              className={classes.addWishBtn}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenResetModal(false)}
              className={classes.addWishBtn}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenOtpModal(true)}
              className={classes.addWishBtn}
            >
              Enter OTP
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openOtpModal}
        onClose={() => {
          setOpenOtpModal(false);
          setOpenResetModal(false);
        }}
        aria-labelledby="enter-otp-modal"
        sx={{ width: 320, margin: "auto" }}
      >
        <Grid
          item
          xs={12}
          container
          direction="column"
          className={classes.OtpModalStyle}
          alignItems="center"
        >
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Reset Password
          </Typography>
          <TextField
            variant="standard"
            id="old-email"
            label="Enter Email"
            onChange={(event) =>
              setForgottenAccountEmail(event.currentTarget.value)
            }
            defaultValue=""
            className={classes.inputField}
          />
          <br />
          <TextField
            variant="standard"
            id="received-otp"
            label="Enter OTP"
            onChange={(event) => setOtp(event.currentTarget.value)}
            defaultValue=""
            className={classes.inputField}
          />
          <br />
          <TextField
            variant="standard"
            type="password"
            id="new-password"
            label="Enter New Password"
            onChange={(event) => setPassword(event.currentTarget.value)}
            defaultValue=""
            className={classes.inputField}
          />
          <br />
          <Grid item xs container justifyContent="space-evenly">
            <Button
              variant="contained"
              onClick={resetPassword}
              className={classes.addWishBtn}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setOpenOtpModal(false);
                setOpenResetModal(false);
              }}
              className={classes.addWishBtn}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </ThemeProvider>
  );
};

export default Login;
