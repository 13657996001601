// TogglePublishButton.js
import React from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  Tooltip
} from "@mui/material";
import PUBLISH_BRAND_GRAPH from "../../graphql/mutations/publishBrandGraph";
import { toast } from "react-toastify";

const PublishButton = ({ graphId, userId, campaignIds, userRole }) => {
  // Use the useMutation hook to call the mutation
  const [publishBrandGraph, { loading, error }] = useMutation(PUBLISH_BRAND_GRAPH);
  if (userRole !== "Admin") return null;

  const handlePublish = () => {
    if (campaignIds.length === 0) {
      toast.error("Please select campaigns to publish");
    } else {
      publishBrandGraph({
        variables: {
          graphId: graphId,
          userId: userId,
          campaignIds: campaignIds,
        },
      }).then(response => {
        toast.success("Published")
      }).catch(err => {
        toast.error('Error publishing graph:', err);
      });
    }
  };

  if (loading) toast.success("Publishing");
  if (error) toast.error("An error occurred while publishing");

  return (
    <Tooltip title="Publish Campaigns" placement="top">
      <Button variant="contained" color="primary" 
        onClick={handlePublish}
        sx={{
          borderRadius: 1,
          padding: 1,
          minWidth: 40
        }}
      >
        ✓
      </Button>
    </Tooltip>
  );

};

export default PublishButton;
