import {Grid, Tab, Button, Tabs, Card, Container} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, {useEffect, useState} from "react";

const TaskGallery = ({ submissions, reset }) => {
  const [landscapeImages, setLandscapeImages] = useState([]);
  const [portraitImages, setPortraitImages] = useState([]);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const loadImages = [];
    const videoSubmissions = [];

    submissions.forEach((submission) => {
      const fileType = submission.split(/[#?]/)[0].split(".").pop().trim();
      if (!(fileType === "jpg" || fileType === "jpeg" || fileType === "png")) {
        videoSubmissions.push(submission);
      } else {
        loadImages.push(
            new Promise((resolve, reject) => {
              const img = new Image();
              img.src = submission;
              img.onload = function() {
                resolve({ src: submission, isLandscape: this.width > this.height });
              };
              img.onerror = () => {
                console.error("Failed to load image:", submission);
                resolve(null); // Resolve the promise with null if the image fails to load
              };
            })
        );
      }
    });

    setVideos([]); // Clear the videos state
    setVideos(videoSubmissions); // Then set the new videos

    Promise.all(loadImages)
        .then((images) => {
          const imagesFiltered = images.filter((img) => img !== null);
          const landscape = imagesFiltered.filter((img) => img.isLandscape).map((img) => img.src);
          const portrait = imagesFiltered.filter((img) => !img.isLandscape).map((img) => img.src);
          setLandscapeImages(landscape);
          setPortraitImages(portrait);
        })
        .catch((error) => {
          console.error("Failed to load images:", error);
        });
  }, [submissions]);

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent="center"
      sx={{ marginBottom: 2 }}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "rgba(255, 163, 199, 0.3)" }}
      >
        <Tabs
          value={0}
          aria-label="influencer-listing-switch"
          sx={{ maxWidth: 450 }}
        >
          <Tab label="Task Gallery" />
        </Tabs>
        <Button variant="contained" onClick={reset()} sx={{ height: 40 }}>
          Reset
        </Button>
      </Grid>
      <Container maxWidth={'xl'}>
        <Grid
            item xs={12} mt={2}
        >
          <Grid item xs={12} justifyContent="space-around" mt={12}>
            {portraitImages.map((submission, index) => (
                <TaskItem item={submission} index={index} />
            ))}
          </Grid>
          <Grid item xs={12} justifyContent="space-around" mt={-1}>
            {landscapeImages.map((submission, index) => (
                <TaskItem item={submission} index={index} isLandscape={true} />
            ))}
          </Grid>
          <Grid item xs={12} justifyContent="space-around" mt={7}>
            {videos.map((submission, index) => (
                <TaskItem item={submission} index={index} />
            ))}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default TaskGallery;

function TaskItem({ item, index, isLandscape = false }) {

  function is_it_an_image(url) {
    const fileType = url.split(/[#?]/)[0].split(".").pop().trim();
    return fileType === "jpg" || fileType === "jpeg" || fileType === "png";
  }

  return (
      <div style={{ position: "relative", display: "inline-block" }}>
        {is_it_an_image(item) ? (
            isLandscape ? (
                <img alt={`gallery-post-${index}`} src={item} style={{ width: "600px", height: "400px" }} />
            ) : (
                <img alt={`gallery-post-${index}`} src={item} style={{ width: "400px", height: "600px" }} />
            )
        ) : (
            <video width="320" height="568" controls>
              <source src={item} type="video/mp4"/>
            </video>
        )}
      </div>
  );
}
