import React from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  Tooltip
} from "@mui/material";
import UNPUBLISH_BRAND_GRAPH from "../../graphql/mutations/unpublishBrandGraph";
import { toast } from "react-toastify";

const UnpublishButton = ({ graphId, userId, campaignIds, userRole }) => {
  // Use the useMutation hook to call the mutation
  const [unpublishBrandGraph, { loading, error }] = useMutation(UNPUBLISH_BRAND_GRAPH);
  if (userRole !== "Admin") return null;

  const handleUnpublish = () => {
    if (campaignIds.length === 0) {
      toast.error("Please select campaigns to unpublish");
    } else {
      unpublishBrandGraph({
        variables: {
          graphId: graphId,
          userId: userId,
          campaignIds: campaignIds,
        },
      }).then(response => {
        toast.success("Unpublished")
      }).catch(err => {
        toast.error('Error publishing graph:', err);
      });
    }
  };

  if (loading) toast.success("Unpublishing");
  if (error) toast.error("An error occurred while unpublishing");

  return (
    <Tooltip title="Unpublish Campaigns" placement="top">
      <Button
        variant="contained"
        color="secondary"
        onClick={handleUnpublish}
        sx={{
          borderRadius: 1,
          padding: 1,
          minWidth: 40
        }}
      >
        X
      </Button>
    </Tooltip>
  );
};

export default UnpublishButton;
