import { gql } from "@apollo/client";

const GET_INFLUENCERS_FOR_TASKS = gql`
  query getContractedInfluencers($campaignId: ID!) {
    getContractedInfluencers(campaignId: $campaignId) {
      id
      influencer {
        id
        image
        name
      }
    }
  }
`;

export default GET_INFLUENCERS_FOR_TASKS;
