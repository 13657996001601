import React from "react";
import { Grid, Typography } from "@mui/material";
import InvoiceCard from "./invoiceCard";
import Checklist from "./checklist";

const Dashboard = () => {
  return (
    <Grid
      item
      xs={11}
      container
      justifyContent="center"
      sx={{ padding: 2 }}
      direction="row"
    >
      <Grid
        item
        xs={12}
        sx={{ marginTop: 5 }}
        container
        justifyContent="center"
      >
        <Grid item xs={8}>
          <Checklist
            title="To Do"
            list={[
              { id: "profile_pic", label: "Upload Profile Picture" },
              { id: "campaigns", label: "Create a campaign" },
            ]}
          />
        </Grid>
      </Grid>
      {/* <Grid item xs={12} sx={{ marginTop: 3 }} container>
        <Grid item xs={12}>
          <Typography variant="h5"> Upcoming Invoices </Typography>
        </Grid>
        <InvoiceCard
          faces={[
            "http://i.pravatar.cc/300?img=2",
            "http://i.pravatar.cc/300?img=3",
          ]}
          endDate={"December 1, 2022"}
          fee={200}
        />
        <InvoiceCard
          faces={[
            "http://i.pravatar.cc/300?img=1",
            "http://i.pravatar.cc/300?img=2",
            "http://i.pravatar.cc/300?img=3",
            "http://i.pravatar.cc/300?img=4",
            "http://i.pravatar.cc/300?img=3",
          ]}
          endDate={"December 9, 2022"}
          fee={820}
        />
        <InvoiceCard
          faces={[
            "http://i.pravatar.cc/300?img=1",
            "http://i.pravatar.cc/300?img=2",
            "http://i.pravatar.cc/300?img=4",
          ]}
          endDate={"December 12, 2022"}
          fee={400}
        />
        <InvoiceCard
          faces={[
            "http://i.pravatar.cc/300?img=1",
            "http://i.pravatar.cc/300?img=2",
            "http://i.pravatar.cc/300?img=3",
            "http://i.pravatar.cc/300?img=4",
            "http://i.pravatar.cc/300?img=1",
            "http://i.pravatar.cc/300?img=4",
          ]}
          endDate={"December 17, 2022"}
          fee={1370}
        />
      </Grid> */}
    </Grid>
  );
};

export default Dashboard;
