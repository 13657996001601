import React, { useState } from "react";
import S3 from "aws-s3";
import s3_config from "../../config/s3_config";
import { Button, Grid } from "@mui/material";
import {imageQuality} from "../sharedComponents/imageQuality"

window.Buffer = window.Buffer || require("buffer").Buffer;

const S3Client = new S3(s3_config);

const ProfilePicUploader = ({ setPP, source = "signup" }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/svg+xml', 'image/avif'];
      const isGoodQuality = await imageQuality(file);
      if (!isGoodQuality) {
        alert('Please upload a better quality image file.');
      }
      else if (validImageTypes.includes(file.type)) {
        setSelectedFile(file);
      } 
      else {
        alert('Please upload an image file.');
        setSelectedFile(null);
        e.target.value = ''; // Reset the file input if invalid file is selected
      }
    }
  };

  const handleUpload = async (file) => {
    S3Client.uploadFile(file)
      .then((data) => setPP(data.location))
      .catch((err) => {});
  };

  return (
    <Grid
      item
      xs={12}
      container
      sx={{ marginTop: 2 }}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <input
        style={{ display: "none" }}
        id="contained-button-file"
        type="file"
        onChange={handleFileInput}
        accept="image/*"
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          {source === "signup" ? "Select Logo" : "Select Gallery Images"}
        </Button>
      </label>
      <Button
        variant="contained"
        color="primary"
        component="span"
        onClick={() => handleUpload(selectedFile)}
        disabled={selectedFile === null}
        sx={{ marginTop: 2 }}
      >
        Upload Image
      </Button>
    </Grid>
  );
};

export default ProfilePicUploader;
