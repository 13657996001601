import { createStyles, makeStyles } from "@mui/styles";
import ChatBanner from "../../assets/graphs-banner.jpeg"

const styles = makeStyles((theme) =>
  createStyles({
    mainGrid: {
      maxWidth: "calc(100vw - 50px)!important",
      height: "calc(100vh - 50px)!important",
      padding: "20px 5px 10px 20px",
      backgroundColor: "#141414",
      color: "#ffffff",
    },
    tab: {
      color: "#ffffff !important",
      "&[aria-selected=true]": {
        backgroundColor: "#FFA3C7",
        border: 0,
      },
    },
    banner: {
      height: "20vh",
      padding: "20px 120px 20px 20px",
      width: "100%",
      backgroundImage: `url(${ChatBanner})`, // Correct usage of a template literal because it's a JS object
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 5px",
      color: "#2d3436",
      objectFit: "contain"
    }
  })
);

export default styles;
