import { gql } from "@apollo/client";

const GET_TIKTOK_STATS = gql`
  query getTiktokStats($orderBy: String!, $countrySort: String) {
    getTiktokStats(orderBy: $orderBy, countrySort: $countrySort) {
      id
      accountVisibility
      accountsEngaged
      videoViews
      femaleRatio
      following
      growthRate
      link
      maleRatio
      topAgeRange
      topCity
      topCityPercentage
      topCountry
      topCountryPercentage
      user {
        id
        image
        country
        city
        name
      }
    }
  }
`;

export default GET_TIKTOK_STATS;
